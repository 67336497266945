import React, { createContext, useContext, useEffect, useState } from 'react';

type GameContextType = {
    animateMoveScreenView: () => void;
    animateMoveScreen: boolean;
    isMobile: boolean;
};

const GameContext = createContext<GameContextType>(null!);
export const useGame = () => useContext(GameContext);

export const GameProvider = ({ children }: { children: React.ReactNode }) => {
    const [animateMoveScreen, setAnimateMoveScreen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const animateMoveScreenView = () => {
        setAnimateMoveScreen(true);
        setTimeout(() => {
            setAnimateMoveScreen(false);
        }, 2000);
    };

    useEffect(() => {
        const checkIsMobile = () => setIsMobile(document.body.clientWidth < 1024);
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);
        return () => window.removeEventListener('resize', checkIsMobile);
    }, []);

    return <GameContext.Provider value={{ animateMoveScreenView, animateMoveScreen, isMobile }}>{children}</GameContext.Provider>;
};
