import { PRESENT_TYPE } from '@repo/types';
import { IMAGES } from '../services/ImageCachingService';

export const presentImages: Record<PRESENT_TYPE, IMAGES> = {
    [PRESENT_TYPE.BANANA]: IMAGES.PRESENT_TYPE_BANANA,
    [PRESENT_TYPE.BERRIES]: IMAGES.PRESENT_TYPE_BERRIES,
    [PRESENT_TYPE.CITRUS]: IMAGES.PRESENT_TYPE_CITRUS,
    [PRESENT_TYPE.GRAPES]: IMAGES.PRESENT_TYPE_GRAPES,
};

export const characterPresentImages: Record<PRESENT_TYPE, IMAGES | null> = {
    [PRESENT_TYPE.BANANA]: IMAGES.CHARACTER_PRESENT_TYPE_BANANA,
    [PRESENT_TYPE.BERRIES]: null,
    [PRESENT_TYPE.CITRUS]: IMAGES.CHARACTER_PRESENT_TYPE_CITRUS,
    [PRESENT_TYPE.GRAPES]: IMAGES.CHARACTER_PRESENT_TYPE_GRAPES,
};
