import { cn } from '../utils';

interface NameTagProps {
    name: string;
    className?: string;
}

export const NameTag = ({ name, className }: NameTagProps) => {
    return (
        <p
            className={cn(
                'font-fredoka text-md whitespace-pre rounded-lg bg-black/75 px-2 py-0.5 font-bold text-white lg:text-2xl',
                className,
            )}
        >
            <span className="drop-shadow-solidGray">{name}</span>
        </p>
    );
};
