import { SoundKey, sounds } from '../data';
import { Howler, Howl } from 'howler';
import { SimpleEventDispatcher } from 'strongly-typed-events';

export default class AudioService {
    static muted = false;
    static onMutedChange = new SimpleEventDispatcher<boolean>();

    private static playingSounds: Record<Partial<SoundKey>, Howl> = {} as Record<Partial<SoundKey>, Howl>;

    public static play(key: SoundKey, volume = 1, fadeDuration = 0) {
        const sound =
            sounds[key] instanceof Array
                ? (sounds[key] as Array<Howl>)[Math.floor(Math.random() * (sounds[key] as Array<Howl>).length)]
                : (sounds[key] as Howl);

        this.stop(key);
        delete this.playingSounds[key];

        this.playingSounds[key] = sound;

        sound.load().play();
        sound.fade(0, volume, fadeDuration);

        return sound;
    }

    public static stop(key: SoundKey) {
        const sound = this.playingSounds[key];
        return sound?.stop();
    }

    public static pause(key: SoundKey) {
        const sound = this.playingSounds[key];
        return () => sound?.play();
    }

    public static resume(key: SoundKey) {
        const sound = this.playingSounds[key];
        return sound?.play();
    }

    public static setVolume(key: SoundKey, volume: number) {
        const sound = this.playingSounds[key];
        return sound?.volume(volume);
    }

    public static muteAll() {
        AudioService.muted = true;
        localStorage.setItem('audio-muted', 'true');
        this.onMutedChange.dispatch(true);
        return Howler.mute(true);
    }

    public static unmuteAll() {
        AudioService.muted = false;
        localStorage.setItem('audio-muted', 'false');
        this.onMutedChange.dispatch(false);
        return Howler.mute(false);
    }

    public static toggleMute() {
        if (AudioService.muted) AudioService.unmuteAll();
        else AudioService.muteAll();
        return AudioService.muted;
    }

    public static setVolumeAll(volume: number) {
        return Howler.volume(volume);
    }
}
