import './App.css';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRouteController } from './shared/components/ProtectedRouteController';
import { ProtectedRoute } from './ProtectedRoute';
import { AuthProvider } from './shared/services/AuthContext';
import { useEffect } from 'react';
import { GameProvider } from './shared/services/GameContext';
import GameLiftService from './shared/services/GameLiftService';
import AudioService from './shared/services/AudioService';
import { SoundKey } from './shared/data';
import { DebugScreen } from './shared/components/DebugScreen';

function App() {
    useEffect(() => {
        const onBeforeUnload = () => {
            if (!window.location.pathname.startsWith('/room-select'))
                return 'Weet je zeker dat je game wilt verlaten? Je gaat dan uit deze ronde.';
        };

        window.addEventListener('beforeunload', onBeforeUnload);
        return () => window.removeEventListener('beforeunload', onBeforeUnload);
    }, []);

    useEffect(() => {
        const handle = () => {
            const play = () => {
                const sound = AudioService.play(SoundKey.MUSIC_BACKGROUND, 0.1, 2500);
                sound.on('end', () => {
                    play();
                });
                return sound;
            };

            play().on('play', () => window.removeEventListener('click', handle));
        };

        window.addEventListener('click', handle);

        if (localStorage.getItem('audio-muted') === 'true') AudioService.muteAll();

        return () => {
            window.removeEventListener('click', handle);
            AudioService.stop(SoundKey.MUSIC_BACKGROUND);
        };
    });

    GameLiftService.init().then();

    return (
        <div className="App">
            <DebugScreen />
            <AuthProvider>
                <GameProvider>
                    <Routes>
                        <Route path="*" element={<ProtectedRoute component={ProtectedRouteController} />} />
                    </Routes>
                </GameProvider>
            </AuthProvider>
        </div>
    );
}

export default App;
