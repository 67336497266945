import { RoomTileButton } from './RoomTileButton';
import { useState } from 'react';
import { useRoom } from '../../../../shared/services/RoomContext';
import { GameRoomDisplay } from '@repo/types';
import RoomService from '../../../../shared/services/RoomService';
import { Skeleton } from '../../../../shared/components/Skeleton';
import { Config } from '@repo/common';
import AudioService from '../../../../shared/services/AudioService';
import { SoundKey } from '../../../../shared/data';

export const RoomSelectorOverview = () => {
    const { joinRoom } = useRoom();
    const [rooms, setRooms] = useState<GameRoomDisplay[]>(RoomService.rooms);

    RoomService.onUpdate.subscribe((rooms) => {
        rooms.sort((a, b) => a.sort_index - b.sort_index);
        setRooms(rooms);
    });

    const selectRoom = async (id: string) => {
        AudioService.play(SoundKey.CLICK, 0.1);
        await joinRoom(id);
    };

    // const singlePlayer = async () => {
    //     AudioService.play(SoundKey.CLICK, 0.1);
    //     await joinSinglePlayer();
    // };

    return (
        <div
            className="grid w-full max-w-lg grid-cols-4 justify-items-center gap-3 lg:mx-auto lg:grid-cols-5 lg:gap-5"
            data-testid="room-selector-overview"
        >
            {rooms.map((room) => (
                <RoomTileButton key={room.id} icon={room.icon} players={room.players} onClick={() => selectRoom(room.id)} />
            ))}

            {!rooms.length &&
                Array(Config.ROOM_DISPLAY_COUNT)
                    .fill(undefined)
                    .map((_, idx) => (
                        <Skeleton
                            key={`room-tile-button-skeleton-${idx}`}
                            className="size-20 overflow-hidden rounded-xl shadow-solidGray"
                        />
                    ))}

            {/* This is the single player button. It is disabled because the client decided that they didn't want to use it. */}
            {/*{GameLiftService.isReady ? (*/}
            {/*    <DefaultButton*/}
            {/*        text="Tegen de computer"*/}
            {/*        className="col-span-full mt-6 animate-fade-in px-4 py-3 lg:mt-8 lg:px-6 lg:py-4"*/}
            {/*        onClick={singlePlayer}*/}
            {/*    />*/}
            {/*) : null}*/}
        </div>
    );
};
