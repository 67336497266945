import { Character } from './Character';
import { useRoom } from '../../../../shared/services/RoomContext';
import { Navigate } from 'react-router-dom';
import { GamePlayer, PRESENT_TYPE, PresentEffect } from '@repo/types';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { characterPresentImages } from '../../../../shared/data';
import { cn } from '../../../../shared/utils';
import { Config, sleep } from '@repo/common';
import { NameTag } from '../../../../shared/components/NameTag';
import Easing from '../../../../shared/utils/easing';
import { CachedImage, IMAGES } from '../../../../shared/components/CachedImage';
import { SimpleEventDispatcher } from 'strongly-typed-events';
import QuestionService from '../services/questions';
import GTAnimation from '../../../../shared/utils/GTAnimation';

interface GameViewProps {
    playerPresentEffects: Record<string, Record<PRESENT_TYPE, boolean>>;
    distanceIndicator: {
        max: number;
        current: number;
        visible: boolean;
    };
    onPresentEffect: SimpleEventDispatcher<PresentEffect>;
    currentQuestionRef: RefObject<HTMLDivElement>;
}

export const GameView: React.FC<GameViewProps> = ({
    playerPresentEffects,
    distanceIndicator,
    onPresentEffect,
    currentQuestionRef,
}: GameViewProps) => {
    const { room, currentPlayer, otherPlayers, characterNames } = useRoom();

    const [transformedDistanceIndicatorMax, setTransformedDistanceIndicatorMax] = useState<number | null>(null);
    const [distanceIndicatorPosition, setDistanceIndicatorPosition] = useState<number>(0);

    const trackRefs = new Array(Config.ROOM_MAX_PLAYERS).fill(0).map(() => useRef<HTMLDivElement>(null));
    const presentAnimationParent = useRef<HTMLDivElement>(null);

    const currentPlayerRef = useRef<HTMLDivElement>(null);
    const tracksRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const indicatorRef = useRef<HTMLDivElement>(null);
    const animationContainerRef = useRef<HTMLDivElement>(null);

    const [movingPlayers, setMovingPlayers] = useState<GamePlayer[]>([]);
    const [playerPositions, setPlayerPositions] = useState<Record<string, number>>({});

    if (!room) {
        console.error('Room not found');
        return <Navigate to="/room-select" />;
    }

    const calculateIndicatorSize = (max: number, trackRef: RefObject<HTMLDivElement>) => {
        if (!trackRef.current) return;
        const bounds = trackRef.current.getBoundingClientRect();
        setTransformedDistanceIndicatorMax((bounds.height / 100) * max);
    };

    useEffect(() => {
        if (!currentPlayer?.position === undefined) return;

        const element = currentPlayerRef.current as HTMLElement | null;

        setTimeout(() => {
            if (element && (currentPlayer?.position ?? Config.PLAYER_POSITION_BOUNDS[0]) > Config.PLAYER_POSITION_BOUNDS[0]) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }, 450);
    }, [currentPlayer?.position]);

    useEffect(() => calculateIndicatorSize(distanceIndicator.max, tracksRef), [distanceIndicator.max]);

    useEffect(() => {
        const handleResize = () => calculateIndicatorSize(distanceIndicator.max, tracksRef);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [distanceIndicator.max]);

    // Initial scroll from bottom to top animation
    useEffect(() => {
        if (!containerRef.current) return;

        containerRef.current.scrollTop = containerRef.current.scrollHeight;

        sleep(1000).then(async () => {
            if ((currentPlayer?.position ?? Config.PLAYER_POSITION_BOUNDS[0]) > Config.PLAYER_POSITION_BOUNDS[0]) return;

            await new GTAnimation({
                duration: 3000,
                frameRenderer: (frame, { totalFrames }) => {
                    if (!containerRef.current) throw new Error('Container ref not found');

                    const percent = Easing.easeInOutCubic(frame / totalFrames) * 100;

                    // Scroll from the bottom to the top
                    containerRef.current!.scrollTop =
                        containerRef.current!.scrollHeight - (containerRef.current!.scrollHeight * percent) / 100;
                },
            }).play();
        });
    }, []);

    useEffect(() => {
        const position = currentPlayer?.position;
        if (!position) return;

        setTimeout(async () => {
            if (!indicatorRef.current) return;

            indicatorRef.current.animate([{ opacity: 1 }, { opacity: 0 }], {
                duration: 100,
                fill: 'forwards',
            });

            await sleep(200);
            setDistanceIndicatorPosition(position);
            await sleep(50);

            indicatorRef.current.animate([{ opacity: 0 }, { opacity: 1 }], {
                duration: 150,
                fill: 'forwards',
            });
        }, 450);
    }, [currentPlayer?.position]);

    const getPlayerTrackRef = (player: GamePlayer): RefObject<HTMLDivElement> => {
        if (currentPlayer!.track_index === player.track_index) return trackRefs.at(-1)!;
        return trackRefs[player.track_index + (currentPlayer!.track_index > player.track_index ? 1 : -1)];
    };

    const berriesAnimation = async () => {
        const imageOptions = [IMAGES.FLYING_BERRY_1, IMAGES.FLYING_BERRY_2, IMAGES.FLYING_BERRY_3];

        for (let i = 0; i < 10; i++) {
            const element = document.createElement('img');
            element.className = 'absolute size-6 drop-shadow-xl';
            element.style.zIndex = '30';
            element.style.opacity = '0';
            element.style.rotate = `${Math.random() * 360}deg`;
            element.src = imageOptions[Math.floor(Math.random() * imageOptions.length)];

            animationContainerRef.current?.append(element);

            sleep(i * 50).then(async () => {
                element.style.opacity = '1';
                const offsetX = (Math.random() - 0.5) * 1000 * (Math.random() < 0.5 ? 1 : -1);
                const offsetY = (Math.random() - 0.5) * 1000 * (Math.random() < 0.5 ? 1 : -1);

                const animation = new GTAnimation({
                    frameRenderer: async (frame, data) => {
                        if (!currentQuestionRef.current || !currentPlayerRef.current) {
                            element.remove();
                            throw new Error('Current question or player not found');
                        }

                        const flyingElementRect = element.getBoundingClientRect();

                        const startPositionRect = document.body.getBoundingClientRect();
                        const startPosition = {
                            x: startPositionRect.left + startPositionRect.width / 2 - flyingElementRect.width / 2,
                            y: startPositionRect.top + startPositionRect.height / 2 - flyingElementRect.height / 2,
                        };

                        const endPositionRect = currentPlayerRef.current.getBoundingClientRect();
                        const endPosition = {
                            x: endPositionRect.left + endPositionRect.width / 2 - flyingElementRect.width / 2,
                            y: endPositionRect.top + endPositionRect.height / 2 - flyingElementRect.height / 2,
                        };

                        const controlPoint = {
                            x: 0.5 * (startPosition.x + endPosition.x) + offsetX,
                            y: 0.5 * (startPosition.y + endPosition.y) + offsetY,
                        };

                        const t = Easing.easeInOutCubic(frame / data.totalFrames);
                        const x = (1 - t) * (1 - t) * startPosition.x + 2 * (1 - t) * t * controlPoint.x + t * t * endPosition.x;
                        const y = (1 - t) * (1 - t) * startPosition.y + 2 * (1 - t) * t * controlPoint.y + t * t * endPosition.y;

                        element.style.top = `${y}px`;
                        element.style.left = `${x}px`;
                        element.style.transform = `scale(${1 + (1 - t) * 0.5})`;
                    },
                    duration: 1000 - i * 10,
                });

                await animation.play();

                element.remove();
            });
        }
    };

    useEffect(() => {
        const handler = async (data: PresentEffect) => {
            // Don't animate if it's the same player
            if (!room?.players) return;

            if (data.target_player_id == data.sender_player_id) {
                if (data.target_player_id == currentPlayer?.id && data.present_type === PRESENT_TYPE.BERRIES)
                    setTimeout(() => berriesAnimation(), 1600);
                return;
            }

            const sender = room.players.find((player) => player!.id === data.sender_player_id)!;
            const target = room.players.find((player) => player!.id === data.target_player_id)!;

            const senderTrack = getPlayerTrackRef(sender);
            const targetTrack = getPlayerTrackRef(target);

            const keyframes = [
                {
                    top: `calc(${sender.position.toFixed(2)}% - 4rem)`,
                    left: `calc(${senderTrack.current?.offsetLeft ?? 0}px + 2rem)`,
                    scale: 1,
                    filter: `drop-shadow(0 .1rem 0.25rem rgba(0, 0, 0, 0.5))`,
                },
                {
                    scale: 2.5,
                    filter: 'drop-shadow(0 .1rem .75rem rgba(0, 0, 0, 0.5))',
                },
                {
                    top: `calc(${target.position.toFixed(2)}% - 4rem)`,
                    left: `calc(${targetTrack.current?.offsetLeft ?? 0}px + 2rem)`,
                    scale: 1,
                    filter: `drop-shadow(0 .1rem 0.25rem rgba(0, 0, 0, 0.5))`,
                },
            ];

            const element = document.createElement('img');
            element.className = 'absolute size-16 drop-shadow-xl object-contain';
            element.style.zIndex = '1000';
            element.src = characterPresentImages[data.present_type]!;

            presentAnimationParent.current?.append(element);

            element.animate(keyframes, {
                duration: 1000,
                fill: 'forwards',
                easing: 'ease',
            });

            await sleep(1000);

            element.animate([{ opacity: 1 }, { opacity: 0 }], {
                duration: 100,
                fill: 'forwards',
            });

            await sleep(300);

            element.remove();
        };

        onPresentEffect.subscribe(handler);

        return () => {
            onPresentEffect.unsubscribe(handler);
        };
    }, [room]);

    useEffect(() => {
        if (!room) return;

        const movedPlayers = room.players.filter((p) => p.position !== playerPositions[p.id]);
        setMovingPlayers((prev) => [...prev, ...movedPlayers]);
        setPlayerPositions((prev) => {
            const newPositions = { ...prev };
            movedPlayers.forEach((p) => (newPositions[p.id] = p.position));
            return newPositions;
        });

        setTimeout(() => setMovingPlayers((prev) => prev.filter((p) => !movedPlayers.includes(p))), 500);
    }, [room?.players]);

    useEffect(() => {
        const handle = (correct: boolean) => {
            if (!correct || !currentQuestionRef.current || !currentPlayerRef.current) {
                return;
            }

            const imageOptions = [IMAGES.FLYING_BERRY_1, IMAGES.FLYING_BERRY_2, IMAGES.FLYING_BERRY_3];

            for (let i = 0; i < 3; i++) {
                const element = document.createElement('img');
                element.className = 'absolute size-6 drop-shadow-xl';
                element.style.zIndex = '30';
                element.style.opacity = '0';
                element.style.rotate = `${Math.random() * 360}deg`;
                element.src = imageOptions[Math.floor(Math.random() * imageOptions.length)];

                animationContainerRef.current?.append(element);

                sleep(i * 150).then(async () => {
                    element.style.opacity = '1';
                    const offset = (Math.random() - 0.5) * 1000;

                    const animation = new GTAnimation({
                        frameRenderer: async (frame, data) => {
                            if (!currentQuestionRef.current || !currentPlayerRef.current) {
                                element.remove();
                                throw new Error('Current question or player not found');
                            }

                            const flyingElementRect = element.getBoundingClientRect();

                            const startPositionRect = currentQuestionRef.current.getBoundingClientRect();
                            const startPosition = {
                                x: startPositionRect.left + startPositionRect.width / 2 - flyingElementRect.width / 2,
                                y: startPositionRect.top + startPositionRect.height / 2 - flyingElementRect.height / 2,
                            };

                            const endPositionRect = currentPlayerRef.current.getBoundingClientRect();
                            const endPosition = {
                                x: endPositionRect.left + endPositionRect.width / 2 - flyingElementRect.width / 2,
                                y: endPositionRect.top + endPositionRect.height / 2 - flyingElementRect.height / 2,
                            };

                            const controlPoint = {
                                x: 0.5 * (startPosition.x + endPosition.x),
                                y: 0.5 * (startPosition.y + endPosition.y) + offset,
                            };

                            const t = Easing.easeInOutCubic(frame / data.totalFrames);
                            const x = (1 - t) * (1 - t) * startPosition.x + 2 * (1 - t) * t * controlPoint.x + t * t * endPosition.x;
                            const y = (1 - t) * (1 - t) * startPosition.y + 2 * (1 - t) * t * controlPoint.y + t * t * endPosition.y;

                            element.style.top = `${y}px`;
                            element.style.left = `${x}px`;
                            element.style.transform = `scale(${1 + (1 - t) * 0.5})`;
                        },
                        duration: 500 - i * 50,
                    });

                    await animation.play();

                    element.remove();
                });
            }
        };

        QuestionService.onAnswer.subscribe(handle);
        return () => QuestionService.onAnswer.unsubscribe(handle);
    }, []);

    const getPlayerTrack = (player: GamePlayer, trackIndex: number, ref?: RefObject<HTMLDivElement>, isCurrentPlayer?: boolean) => {
        return (
            <div
                className="relative flex flex-col"
                ref={trackRefs[trackIndex]}
                key={player.id}
                data-testid={`game-view-player-${player.id}`}
            >
                <div className="absolute left-1/2 top-0 -z-20 h-full w-4 -translate-x-1/2 rounded-full bg-neutral-800/10"></div>

                {Config.PRESENT_LOCATIONS.filter((location) => location > player.position).map((location, idx) => (
                    <div
                        key={'present-' + player.id + '-' + location}
                        className="absolute left-0 top-0 z-20 ml-16 flex w-full max-w-20 items-end lg:ml-12 lg:max-w-none"
                        style={{
                            height: `${location}%`,
                        }}
                        data-testid={`present-${idx + 1}`}
                    >
                        <div className="ml-4 w-full max-w-12 animate-scale-in text-center md:ml-8 lg:max-w-14 xl:max-w-16">
                            <img alt={'present ' + (idx + 1)} src="/images/present.svg" className="size-full object-contain" />
                        </div>
                    </div>
                ))}

                <div
                    className="transition-all duration-500 ease-in-out"
                    style={{
                        height: `calc(${player.position}% - 8rem)`, // We subtract 8rem as that is the height of the character
                    }}
                    data-testid={`player-position`}
                />

                <div className="relative" data-player={player.id} ref={ref}>
                    <div
                        className={cn(
                            'drop-shadow-solidGray relative size-32 select-none rounded-full p-4 transition-all',
                            playerPresentEffects[player.id]?.BANANA && 'rotate-90',
                            movingPlayers.includes(player) && 'animate-walk',
                        )}
                        style={{
                            transformOrigin: 'bottom center',
                        }}
                        data-testid={`character-${player.id}`}
                    >
                        <Character character={player.character} />
                    </div>

                    <NameTag className="absolute left-1/2 top-0 -translate-x-1/2" name={characterNames[player.character]} />

                    {/*{playerPresentEffects[player.id] && (*/}
                    {/*    <CachedImage*/}
                    {/*        className="absolute inset-0 aspect-square w-full animate-fade-in object-contain"*/}
                    {/*        src={characterPresentImages[playerPresentEffects[player.id]]}*/}
                    {/*        data-testid={`present-effect-${player.id}`}*/}
                    {/*    />*/}
                    {/*)}*/}

                    {playerPresentEffects[player.id]
                        ? Object.entries(playerPresentEffects[player.id])
                              .filter((e) => e[1] && characterPresentImages[e[0] as PRESENT_TYPE])
                              .map(([effect], idx) => (
                                  <CachedImage
                                      src={characterPresentImages[effect as PRESENT_TYPE]!}
                                      className="absolute inset-0 aspect-square w-full animate-fade-in object-contain"
                                      data-testid={`present-effect-${player.id}`}
                                      key={`present-effect-${player.id}-${effect}-${idx}`}
                                  />
                              ))
                        : null}
                </div>

                <div className="absolute top-0 size-full">
                    <div
                        style={{
                            height: `calc(${distanceIndicatorPosition}% - 8rem)`,
                        }}
                    />

                    <div className="h-32"></div>

                    <div className="relative">
                        {isCurrentPlayer && currentPlayer && currentPlayer?.position < 100 ? (
                            <div
                                className={cn(
                                    'absolute left-1/2 top-full -z-10 -mt-[30%] -translate-x-1/2 opacity-0 transition-all duration-75',
                                    distanceIndicator.visible && 'opacity-100',
                                )}
                                data-testid="distance-indicator"
                            >
                                <div
                                    className="relative w-10"
                                    style={{
                                        height: transformedDistanceIndicatorMax + 'px',
                                    }}
                                >
                                    <div
                                        ref={indicatorRef}
                                        className="relative mx-auto w-1.5 transition-all"
                                        style={{
                                            height: distanceIndicator.current + '%',
                                            background: `repeating-linear-gradient(180deg, rgba(0,0,0,.75) 0, rgba(0,0,0,.75) 1rem, transparent 1rem, transparent 2rem)`,
                                        }}
                                    >
                                        <div className="absolute -bottom-3 left-1/2 size-5 -translate-x-1/2 rounded-full border-4 border-black bg-yellow-400"></div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div ref={animationContainerRef} className="pointer-events-none absolute inset-0 z-40 overflow-hidden"></div>

            <div className="relative overflow-y-auto" ref={containerRef} data-testid="game-view">
                <div
                    className="-z-0 bg-no-repeat"
                    style={{
                        aspectRatio: '1472/3296',
                        backgroundImage: `url(${IMAGES.RACE_TRACKS})`,
                        backgroundSize: '100%',
                    }}
                    ref={tracksRef}
                    data-testid="race-track"
                >
                    <div className="relative z-20 mx-auto size-full max-w-[70%] py-20 lg:py-40">
                        <div className="relative grid size-full grid-cols-4 justify-items-center" ref={presentAnimationParent}>
                            {otherPlayers?.map((player, idx) =>
                                player ? getPlayerTrack(player, idx) : <div key={'player-' + idx + '-empty'}></div>,
                            )}
                            {getPlayerTrack(currentPlayer!, 3, currentPlayerRef, true)}
                        </div>

                        <div className="absolute inset-0 size-full py-20 lg:py-40">
                            <div className="relative size-full">
                                {Config.PRESENT_LOCATIONS.map((location) => (
                                    <div
                                        style={{
                                            top: `${location - 0.8}%`,
                                            background: `repeating-linear-gradient(90deg, rgba(0,0,0,.5) 0, rgba(0,0,0,.5) 1rem, transparent 1rem, transparent 2rem)`,
                                        }}
                                        className="absolute inset-0 -z-20 h-1 w-full drop-shadow"
                                        key={`present-location-${location}`}
                                        data-testid={`present-location-${location}`}
                                    ></div>
                                ))}

                                <div
                                    style={{
                                        background: `repeating-linear-gradient(90deg, transparent 0, transparent 2rem, white 2rem, white 4rem)`,
                                    }}
                                    className="absolute bottom-[1%] left-0 -z-20 h-2 w-full drop-shadow-solidGray"
                                    data-testid="finish-line"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
