import { Navigate } from 'react-router-dom';
import { useAuth } from './shared/services/AuthContext';
import React from 'react';

type ProtectedRouteProps = {
    component: React.ComponentType;
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, ...props }) => {
    const isAuthenticated = useAuth();

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    return <Component {...props} />;
};
