import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { Character as CharacterEnum, GamePlayer, GameRoomFull, OP_CODE, OperationBody, RoomState } from '@repo/types';
import RoomService from './RoomService';
import { useLocation, useNavigate } from 'react-router-dom';
import { RTMessage } from '@repo/gamelift-client';
import GameLiftService from './GameLiftService';
import BackendApiService from './BackendApiService';
import CharacterService from './CharacterService';
import AudioService from './AudioService';
import { SoundKey } from '../data';

type RoomContextType = {
    room?: GameRoomFull;
    leaveRoom: () => Promise<void>;
    joinRoom: (roomId: string) => Promise<void>;
    joinSinglePlayer: () => Promise<void>;
    currentPlayer?: GamePlayer;
    otherPlayers?: (GamePlayer | undefined)[];
    characterNames: Record<string, string>;
};

type RoomProviderProps = {
    children: ReactNode;
};

const RoomContext = createContext<RoomContextType | null>(null);

export const useRoom = () => {
    const context = useContext(RoomContext);
    if (!context) {
        throw new Error('useRoom must be inside a RoomProvider');
    }
    return context;
};

export const RoomProvider: FC<RoomProviderProps> = ({ children }) => {
    const [roomData, setRoomData] = useState<GameRoomFull | undefined>(undefined);
    const [currentPlayer, setCurrentPlayer] = useState<GamePlayer | undefined>(
        roomData ? roomData.players.find((p) => p.current_player) : undefined,
    );
    const [otherPlayers, setOtherPlayers] = useState<(GamePlayer | undefined)[]>(new Array(3).fill(undefined));
    const [characterNames, setCharacterNames] = useState<Record<string, string>>({});

    const navigate = useNavigate();
    const location = useLocation();

    const joinRoom = async (id: string) => {
        try {
            await RoomService.joinRoom(id);
        } catch (error) {
            console.error(`Failed to join room ${id}:`, error);
        }
    };

    const joinSinglePlayer = async () => {
        try {
            await RoomService.joinSinglePlayer();
        } catch (error) {
            console.error(`Failed to join single player:`, error);
        }
    };

    const leaveRoom = async () => {
        try {
            await RoomService.leaveRoom();
        } catch (error) {
            console.error('Failed to leave room:', error);
        }
    };

    const handleRoomUpdate = useCallback(
        (message: RTMessage<OperationBody[OP_CODE.CURRENT_ROOM_UPDATE]>) => {
            const previousRoomState = roomData?.state;

            const room = message.getPayloadObject();
            setRoomData(room);

            const currentPlayer = room.players.find((p) => p.current_player);
            setCurrentPlayer(currentPlayer);

            if (currentPlayer) {
                const players: (GamePlayer | undefined)[] = room?.players.filter((p) => p.id !== currentPlayer.id);
                for (let i = 0; i < 3; i++) {
                    players[i] ??= undefined;
                }
                setOtherPlayers(players);
            }

            const selectedNames: Record<string, string> = {};
            for (const character of Object.values(CharacterEnum)) {
                selectedNames[character] = CharacterService.getCharacterName(room?.id ?? '', character, Object.values(selectedNames));
            }
            setCharacterNames(selectedNames);

            if (previousRoomState !== room.state) {
                const env = localStorage.getItem('semsom_env');
                switch (room.state) {
                    case RoomState.CHARACTER_SELECT:
                        if (env && location.pathname.includes('result')) {
                            //if we are a test, don't go to character select as the test may take more time than the timeout.
                            return;
                        } else {
                            navigate('/character-select?room=' + room.id);
                        }
                        break;
                    case RoomState.GAME:
                        navigate('/game?room=' + room.id);
                        break;
                    case RoomState.GAME_OVER: {
                        const player = room.players.find((p) => p.current_player);
                        if (player) {
                            const won = player.position >= 100;
                            BackendApiService.finishRace(won).then();
                        }
                        AudioService.play(SoundKey.GAME_OVER, 1);
                        break;
                    }
                    case RoomState.RESULTS: {
                        navigate('/result?room=' + room.id);
                        break;
                    }
                }
            }
        },
        [navigate, roomData?.state],
    );

    const handleGameOver = useCallback(
        (data: RTMessage<OperationBody[OP_CODE.GAME_OVER]>) => {
            setRoomData({
                ...roomData!,
                state: RoomState.GAME_OVER,
                players: data.getPayloadObject().players,
            });
        },
        [roomData],
    );

    const handleLeaveRoom = useCallback(() => {
        setRoomData(undefined);
        setCurrentPlayer(undefined);
        navigate('/room-select');
    }, [navigate]);

    useEffect(() => {
        GameLiftService.onMessage[OP_CODE.CURRENT_ROOM_UPDATE].subscribe(handleRoomUpdate);
        GameLiftService.onMessage[OP_CODE.GAME_OVER].subscribe(handleGameOver);
        GameLiftService.onMessage[OP_CODE.LEAVE_ROOM].subscribe(handleLeaveRoom);

        return () => {
            GameLiftService.onMessage[OP_CODE.CURRENT_ROOM_UPDATE].unsubscribe(handleRoomUpdate);
            GameLiftService.onMessage[OP_CODE.GAME_OVER].unsubscribe(handleGameOver);
            GameLiftService.onMessage[OP_CODE.LEAVE_ROOM].unsubscribe(handleLeaveRoom);
        };
    });

    useEffect(() => {
        // When the user uses the back or forward buttons in the browser, we leave the room.
        const handle = () => roomData !== undefined && leaveRoom();
        window.addEventListener('popstate', handle);
        return () => window.removeEventListener('popstate', handle);
    }, []);

    return (
        <RoomContext.Provider
            value={{ room: roomData, joinRoom, joinSinglePlayer, currentPlayer, leaveRoom, otherPlayers, characterNames }}
        >
            {children}
        </RoomContext.Provider>
    );
};
