import React from 'react';

interface LeaveRoomButtonProps {
    onClick: () => void;
    text?: string;
    icon?: React.ReactNode;
}

export const LeaveButton = ({ onClick, icon, text = 'Potje verlaten' }: LeaveRoomButtonProps) => {
    return (
        <div className="group absolute left-0 top-0 z-20 h-10 lg:h-20">
            <button
                onClick={onClick}
                className="flex size-full items-center gap-2 fill-white p-2 opacity-85 outline-none transition-all hover:drop-shadow-md lg:p-5"
                data-testid="leave-room-button"
            >
                {icon ? (
                    icon
                ) : (
                    <svg className="aspect-square h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                    </svg>
                )}

                <p className="w-full -translate-x-2 text-xs font-semibold text-white opacity-0 drop-shadow transition-all group-hover:translate-x-0 group-hover:opacity-100 lg:text-base">
                    {text}
                </p>
            </button>
        </div>
    );
};
