import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import BackendApiService from './BackendApiService';

type AuthContextType = {
    checkAuth: () => Promise<boolean>;
    login: () => void;
    logout: () => void;
};

type AuthProviderProps = {
    children: ReactNode;
};

const AuthContext = createContext<AuthContextType>(null!);

export const useAuth = () => useContext(AuthContext);

let checkAuthRequest: Promise<boolean> | undefined;
let redirectingToLogin = false;

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const login = () => {
        if (redirectingToLogin) return;
        redirectingToLogin = true;
        return window.location.replace(BackendApiService.buildLoginUrl(window.location.href));
    };

    const logout = () => {
        // TODO
        alert('TODO: logout');
    };

    const checkAuth = (): Promise<boolean> => {
        if (checkAuthRequest) return checkAuthRequest;
        console.log('Checking auth...');
        checkAuthRequest = new Promise<boolean>((resolve) => {
            BackendApiService.getProfiel()
                .then(() => resolve(true))
                .catch(() => resolve(false))
                .finally(() => {
                    checkAuthRequest = undefined;
                });
        });
        return checkAuthRequest;
    };

    useEffect(() => {
        const check = async () => {
            const isAuthenticated = await checkAuth();
            if (!isAuthenticated) login();
        };

        check().then();
    }, []);

    return <AuthContext.Provider value={{ checkAuth, login, logout }}>{children}</AuthContext.Provider>;
};
