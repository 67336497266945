import { GameRoomDisplay, OP_CODE } from '@repo/types';
import { SimpleEventDispatcher } from 'strongly-typed-events';
import GameLiftService from './GameLiftService';

export default class RoomService {
    public static rooms: GameRoomDisplay[] = [];
    public static onUpdate = new SimpleEventDispatcher<GameRoomDisplay[]>();

    static async init() {
        // Subscribe to room updates so we can update the UI
        GameLiftService.onMessage[OP_CODE.ROOMS_UPDATE].subscribe((message) => {
            this.rooms = message.getPayloadObject();
            this.onUpdate.dispatch(this.rooms);
        });
    }

    /*
     * Method to join a room, or a random room if no room id is provided
     */
    static async joinRoom(roomId?: string) {
        const client = await GameLiftService.client;

        const message = client.newMessage(OP_CODE.JOIN_ROOM, {
            room_id: roomId ? roomId : 'random',
        });

        return client.send(message);
    }

    static async joinSinglePlayer() {
        const client = await GameLiftService.client;
        const message = client.newMessage(OP_CODE.CREATE_SINGLE_PLAYER_ROOM, {});
        return client.send(message);
    }

    static async leaveRoom() {
        const client = await GameLiftService.client;
        client.send(client.newMessage(OP_CODE.LEAVE_ROOM, {}));
    }
}

RoomService.init().then();
