import React, { useState } from 'react';

const options = ['Laat twee duimen zien!', 'Draai een rondje!', 'Spring in de lucht!', 'Doe een dansje!', 'Schud met je billen!'];

interface Props {
    page: 'game' | 'result';
}

const getText = (page: 'game' | 'result'): string => {
    try {
        const data = (localStorage.getItem('wintext') &&
            (JSON.parse(localStorage.getItem('wintext')!) as {
                text: string;
                date: number;
            } | null)) || { text: '', date: 0 };

        if (Date.now() - data.date < 15_000) {
            return data.text;
        }

        switch (page) {
            case 'game': {
                const t = options[Math.floor(Math.random() * options.length)];

                localStorage.setItem(
                    'wintext',
                    JSON.stringify({
                        text: t,
                        date: Date.now(),
                    }),
                );

                return t;
            }
            case 'result': {
                return getText('game');
            }
        }
    } catch (e) {
        console.error(e);
        return 'Laat twee duimen zien!';
    }
};

export const WinText: React.FC<Props> = ({ page }: Props) => {
    const [text] = useState<string>(getText(page));
    return <>{text}</>;
};
