import { Character as CharacterEnum } from '@repo/types';
import { characterImages } from '../../../../shared/data';
import { cn } from '../../../../shared/utils';

interface CharacterProps {
    character: CharacterEnum;
    className?: string;
}

export const Character = (props: CharacterProps) => {
    const { character } = props;

    return (
        <div className={cn('grid size-full place-items-center', props.className)} data-testid={`character-container-${character}`}>
            {character ? (
                <img
                    src={characterImages[character]}
                    className="size-full object-contain"
                    alt="character"
                    data-testid={`character-image-${character}`}
                />
            ) : (
                <div className="absolute z-10 text-6xl text-white" data-testid="character-placeholder">
                    ⌛️
                </div>
            )}
        </div>
    );
};
