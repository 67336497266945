import { cn } from '../utils';

interface DefaultButtonProps {
    text: string;
    onClick: () => void;
    className?: string;
}

export const DefaultButton = ({ text, onClick, className }: DefaultButtonProps) => {
    return (
        <button
            className={cn(
                'shadow-solidGray mx-auto flex w-fit items-center justify-center gap-4 rounded-xl border-4 border-black bg-white p-4 px-6 text-center text-xl font-bold text-black outline-none focus-visible:ring focus-visible:ring-blue-500/75 lg:min-w-40 lg:text-2xl',
                className,
            )}
            onClick={onClick}
        >
            {text}
        </button>
    );
};
