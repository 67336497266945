import { PlayerSession } from '@aws-sdk/client-gamelift';
import { ConnectionToken, GameLiftRealtimeClient, RTMessage } from '@repo/gamelift-client';
import { SimpleEventDispatcher } from 'strongly-typed-events';
import { Config, sleep } from '@repo/common';
import { OP_CODE, OperationBody } from '@repo/types';

export default class GameLiftService {
    static readonly onReady = new SimpleEventDispatcher<GameLiftRealtimeClient>();

    static readonly onMessage: {
        [K in keyof OperationBody]: Readonly<SimpleEventDispatcher<RTMessage<OperationBody[K]>>>;
    } = {
        [OP_CODE.LOGIN_REQUEST]: new SimpleEventDispatcher(),
        [OP_CODE.LOGIN_RESULT]: new SimpleEventDispatcher(),
        [OP_CODE.PING_RESULT]: new SimpleEventDispatcher(),
        [OP_CODE.UDP_CONNECT]: new SimpleEventDispatcher(),
        [OP_CODE.UDP_CONNECT_SERVER_ACK]: new SimpleEventDispatcher(),
        [OP_CODE.UDP_CONNECT_CLIENT_ACK]: new SimpleEventDispatcher(),
        [OP_CODE.PLAYER_READY]: new SimpleEventDispatcher(),
        [OP_CODE.JOIN_GROUP]: new SimpleEventDispatcher(),
        [OP_CODE.LEAVE_GROUP]: new SimpleEventDispatcher(),
        [OP_CODE.REQUEST_GROUP_MEMBERSHIP]: new SimpleEventDispatcher(),
        [OP_CODE.GROUP_MEMBERSHIP_UPDATE]: new SimpleEventDispatcher(),
        [OP_CODE.VERIFY_IDENTITY]: new SimpleEventDispatcher(),
        [OP_CODE.VERIFY_IDENTITY_RESULT]: new SimpleEventDispatcher(),
        [OP_CODE.PLAYER_CONNECT]: new SimpleEventDispatcher(),
        [OP_CODE.PLAYER_DISCONNECT]: new SimpleEventDispatcher(),

        [OP_CODE.ROOMS_UPDATE]: new SimpleEventDispatcher(),
        [OP_CODE.JOIN_ROOM]: new SimpleEventDispatcher(),
        [OP_CODE.CURRENT_ROOM_UPDATE]: new SimpleEventDispatcher(),
        [OP_CODE.GAME_POSITION_UPDATE]: new SimpleEventDispatcher(),
        [OP_CODE.GAME_COLLECT_PRESENT]: new SimpleEventDispatcher(),
        [OP_CODE.GAME_PRESENT_EFFECT]: new SimpleEventDispatcher(),
        [OP_CODE.GAME_OVER]: new SimpleEventDispatcher(),
        [OP_CODE.UPDATE_CHARACTER]: new SimpleEventDispatcher(),
        [OP_CODE.LEAVE_ROOM]: new SimpleEventDispatcher(),
        [OP_CODE.CREATE_SINGLE_PLAYER_ROOM]: new SimpleEventDispatcher(),
    } as const;

    private static _readyPromise?: Promise<GameLiftRealtimeClient>;
    private static _isLoading = false;

    private static _isReady = false;

    static get isReady() {
        return this._isReady;
    }

    private static _client?: GameLiftRealtimeClient;

    // This will throw an error if it fails to connect
    static get client() {
        return this.init();
    }

    static async init(): Promise<GameLiftRealtimeClient> {
        if (!this._client && !this._readyPromise) {
            this._readyPromise = this.initializeClient();
        }

        return this._readyPromise!;
    }

    private static async initializeClient(): Promise<GameLiftRealtimeClient> {
        if (this._isLoading) {
            console.warn('GameLift client already initialized');
            return this._readyPromise!;
        }

        this._isLoading = true;

        if (this._isReady || this._client) {
            console.warn('GameLift client already initialized');
            return this._client!;
        }

        console.log('[GLS] Initialising GameLift client');

        try {
            const playerSession = await this.getPlayerSession();
            if (!playerSession?.PlayerSessionId || !playerSession?.DnsName || !playerSession?.Port || !playerSession?.PlayerId) {
                throw new Error('No player session found');
            }

            const connectionToken: ConnectionToken = {
                // When creating a fleet, make sure it has a certificate, otherwise it won't be able to connect using wss
                serverEndpoint: `wss://${playerSession?.DnsName}:${playerSession?.Port}`,
                playerSessionId: playerSession?.PlayerSessionId,
                connectedPeerId: 0,
            };

            const realtimeClient = new GameLiftRealtimeClient(connectionToken, {
                player_id: sessionStorage.getItem('player_id'),
                reconnect_token: sessionStorage.getItem('reconnect_token'),
            });

            realtimeClient.connect();

            const connectionStartTime = Date.now();

            while (!realtimeClient.connected) {
                await new Promise((resolve) => setTimeout(resolve, 10));
                console.log('[GLS] Waiting for connection...');

                if (Date.now() - connectionStartTime > 5000) {
                    throw new Error('Timed out waiting for connection');
                }
            }

            console.log('[GLS] Connected to GameLift Realtime!');

            realtimeClient.onDataReceived.subscribe((message) => {
                if (message.opCode === OP_CODE.PLAYER_CONNECT) {
                    const payload = (message as RTMessage<OperationBody[OP_CODE.PLAYER_CONNECT]>).getPayloadObject();
                    sessionStorage.setItem('player_id', payload.player_id);
                    sessionStorage.setItem('reconnect_token', payload.reconnect_token);
                }

                return this.onMessage[message.opCode].dispatch(message);
            });

            realtimeClient.onClose.subscribe(async (event) => {
                if (event.wasClean) {
                    console.log('[GLS] Disconnected cleanly from GameLift Realtime');
                    return;
                }

                console.warn('[GLS] Disconnected from GameLift Realtime, attempting to reconnect in 5 seconds...', event);

                await sleep(5000);
                console.log('[GLS] Reconnecting to GameLift Realtime...');

                this._client = undefined;
                this._isReady = false;
                this._isLoading = false;
                this._readyPromise = this.initializeClient();
            });

            this._client = realtimeClient;
            this._readyPromise = Promise.resolve(this._client);
            this._isReady = true;
            this.onReady.dispatch(this._client);
        } finally {
            this._isLoading = false;
        }

        return this._client;
    }

    private static async getPlayerSession(): Promise<PlayerSession | null> {
        const response = await fetch(Config.ENDPOINTS.GAMELIFT_LOGIN, {
            credentials: 'include',
            cache: 'no-cache',
            headers: {
                accept: 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    }
}
