import { Character as CharacterEnum } from '@repo/types';
import { IMAGES } from '../services/ImageCachingService';

export const characterImages: Record<CharacterEnum, IMAGES> = {
    [CharacterEnum.EXAMPLE_1]: IMAGES.CHARACTERS_1,
    [CharacterEnum.EXAMPLE_2]: IMAGES.CHARACTERS_2,
    [CharacterEnum.EXAMPLE_3]: IMAGES.CHARACTERS_3,
    [CharacterEnum.EXAMPLE_4]: IMAGES.CHARACTERS_4,
    [CharacterEnum.EXAMPLE_5]: IMAGES.CHARACTERS_5,
    [CharacterEnum.EXAMPLE_6]: IMAGES.CHARACTERS_6,
    [CharacterEnum.EXAMPLE_7]: IMAGES.CHARACTERS_7,
    [CharacterEnum.EXAMPLE_8]: IMAGES.CHARACTERS_8,
};

export const characterImagesCropped: Record<CharacterEnum, IMAGES> = {
    [CharacterEnum.EXAMPLE_1]: IMAGES.CHARACTERS_1_CROPPED,
    [CharacterEnum.EXAMPLE_2]: IMAGES.CHARACTERS_2_CROPPED,
    [CharacterEnum.EXAMPLE_3]: IMAGES.CHARACTERS_3_CROPPED,
    [CharacterEnum.EXAMPLE_4]: IMAGES.CHARACTERS_4_CROPPED,
    [CharacterEnum.EXAMPLE_5]: IMAGES.CHARACTERS_5_CROPPED,
    [CharacterEnum.EXAMPLE_6]: IMAGES.CHARACTERS_6_CROPPED,
    [CharacterEnum.EXAMPLE_7]: IMAGES.CHARACTERS_7_CROPPED,
    [CharacterEnum.EXAMPLE_8]: IMAGES.CHARACTERS_8_CROPPED,
};
