import { useEffect, useState } from 'react';
import { TwoColumnGrid } from '../../../shared/components/TwoColumnGrid';
import { TimerButton } from '../../../shared/components/TimerButton';
import { Character } from '../../game/shared/components/Character';
import { useRoom } from '../../../shared/services/RoomContext';
import { Character as CharacterEnum, OP_CODE } from '@repo/types';
import { LoadBar } from '../../../shared/components/LoadBar';
import { Config } from '@repo/common';
import GameLiftService from '../../../shared/services/GameLiftService';
import { cn } from '../../../shared/utils';
import { Navigate } from 'react-router-dom';
import { characterImagesCropped, SoundKey } from '../../../shared/data';
import AudioService from '../../../shared/services/AudioService';
import { WoodenBackground } from '../../../shared/components/WoodenBackground';
import { LeaveButton } from '../../../shared/components/LeaveButton';
import { NameTag } from '../../../shared/components/NameTag';
import { CachedImage, IMAGES } from '../../../shared/components/CachedImage';

export const CharacterSelect = () => {
    const characters = Object.values(CharacterEnum);
    const { room, currentPlayer, leaveRoom, otherPlayers, characterNames } = useRoom();

    const [awaitingStart, setAwaitingStart] = useState(false);
    const [selectedCharacters, setSelectedCharacters] = useState<CharacterEnum[]>([]);

    useEffect(() => {
        setSelectedCharacters(room?.players.flatMap((p) => p.character) ?? []);
    }, [room?.players]);

    if (!room) {
        console.error('Room not found');
        return <Navigate to="/room-select" />;
    }

    const handleSelectCharacter = async (character: CharacterEnum) => {
        AudioService.play(SoundKey.CLICK, 0.1);

        const client = await GameLiftService.client;
        client.send(
            client.newMessage(OP_CODE.UPDATE_CHARACTER, {
                character,
            }),
        );
    };

    return (
        <TwoColumnGrid leftColumnSize="50%" rightColumnSize="50%" height="100dvh">
            <div className="relative overflow-hidden" data-testid="character-select-background">
                <div
                    style={{
                        backgroundImage: `url(${IMAGES.RACE_TRACKS})`,
                        backgroundSize: '115%',
                        backgroundPosition: 'top',
                        backgroundRepeat: 'no-repeat',
                        height: '100%',
                        position: 'absolute',
                        top: '0',
                        width: '100%',
                        zIndex: '-1',
                    }}
                ></div>

                <div
                    className="mx-auto mt-[20%] grid max-w-[82%] grid-cols-4 items-center justify-items-center"
                    data-testid="other-players"
                >
                    {otherPlayers?.map((player, index) =>
                        player ? (
                            <div key={player.id} className="relative">
                                <div
                                    className="aspect-square size-32 select-none p-4 drop-shadow-solidGray transition-all"
                                    data-testid={`other-player-${player.id}`}
                                >
                                    <Character character={player.character} />
                                </div>

                                <NameTag className="absolute left-1/2 top-0 -translate-x-1/2" name={characterNames[player.character]} />
                            </div>
                        ) : (
                            <div
                                key={index}
                                className="grid aspect-square size-8 animate-pulse place-items-center text-2xl drop-shadow-solidGray lg:size-16 lg:text-6xl"
                                data-testid={`other-player-placeholder-${index}`}
                            >
                                <CachedImage src={IMAGES.ZANDLOPER} className="size-full object-contain" />
                            </div>
                        ),
                    )}

                    {currentPlayer?.character && (
                        <div data-testid="current-player" className="relative">
                            <div className="aspect-square size-32 select-none p-4 drop-shadow-solidGray transition-all">
                                <Character character={currentPlayer.character} />
                            </div>
                            <NameTag className="absolute left-1/2 top-0 -translate-x-1/2" name={characterNames[currentPlayer.character]} />
                        </div>
                    )}
                </div>

                <div className="absolute left-[2.5%] top-1/3 w-fit -rotate-6 lg:left-[5%]" data-testid="sign-board">
                    <div className="relative w-12 lg:w-20">
                        <CachedImage src={IMAGES.ROOM_SIGN} alt="room icon sign" className="w-full" />

                        <div className="absolute top-0">
                            <img src={room.icon} alt="" className="p-2 drop-shadow lg:p-4" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="relative flex h-full items-center justify-center lg:h-screen" data-testid="character-selection">
                <LeaveButton onClick={leaveRoom} />

                <div className="size-full max-h-[75dvh] lg:max-h-screen">
                    <WoodenBackground />

                    {awaitingStart ? (
                        <div
                            className="flex h-full max-h-[75dvh] flex-col items-center justify-center gap-4 lg:max-h-screen"
                            data-testid="awaiting-start"
                        >
                            <h2 className="text-3xl font-semibold text-white drop-shadow-solidGray lg:text-4xl">Wachten op spelers..</h2>
                            <div className="mx-auto mt-4 h-8 w-96 max-w-full rounded-lg bg-gray-800/90 shadow-solidGray">
                                <LoadBar
                                    className="bg-yellow-400"
                                    duration={new Date(room.starts_at).getTime() - Date.now()}
                                    timerEnded={() => {}}
                                />
                            </div>
                        </div>
                    ) : (
                        <div
                            className="flex h-full max-h-[75dvh] flex-col items-center justify-center gap-6 overflow-y-auto py-16 lg:max-h-screen lg:gap-16 lg:py-16"
                            data-testid="character-options"
                        >
                            <h1 className="text-pretty text-2xl font-bold text-white drop-shadow-solidGray lg:text-4xl">Kies een dier</h1>

                            <div className="flex w-fit max-w-2xl flex-wrap items-center justify-center gap-2 px-4 xl:gap-6 xl:px-4">
                                {characters.map((character) => (
                                    <button
                                        onClick={() => {
                                            if (!selectedCharacters.includes(character)) handleSelectCharacter(character).then();
                                        }}
                                        key={character}
                                        className={cn(
                                            'shadow-solidGray relative flex aspect-square w-full max-w-28 cursor-pointer items-center justify-center rounded-full border-2 border-black bg-gray-300 outline-none transition-all hover:z-[150] hover:scale-105 lg:max-w-36 2xl:max-w-48',
                                            currentPlayer?.character === character
                                                ? 'cursor-pointer bg-yellow-300 ring-4 ring-white/75 hover:scale-105 lg:ring-[.65rem]'
                                                : selectedCharacters.includes(character)
                                                  ? 'cursor-not-allowed bg-gray-800'
                                                  : 'cursor-pointer bg-gray-300 hover:scale-105',
                                        )}
                                        data-testid={`character-button`}
                                    >
                                        <img
                                            src={characterImagesCropped[character]}
                                            className={cn(
                                                'size-full object-contain p-5 lg:p-8',
                                                selectedCharacters.includes(character) &&
                                                    currentPlayer?.character !== character &&
                                                    'opacity-50',
                                            )}
                                            alt={character}
                                            data-testid={`character-image-${character}`}
                                        />

                                        <NameTag
                                            className="absolute -bottom-2 left-1/2 z-[100] -translate-x-1/2 lg:-bottom-4"
                                            name={characterNames[character]}
                                        />
                                    </button>
                                ))}
                            </div>

                            <TimerButton
                                duration={Config.ROOM_WAIT_TIME}
                                text="Kies"
                                timerEnded={() => setAwaitingStart(true)}
                                onClick={() => {
                                    AudioService.play(SoundKey.CLICK, 0.1);
                                    setAwaitingStart(true);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </TwoColumnGrid>
    );
};
