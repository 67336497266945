import { Howl } from 'howler';

export enum SoundKey {
    CLICK = 'CLICK',
    QUESTION_WRONG = 'QUESTION_WRONG',
    QUESTION_CORRECT = 'QUESTION_CORRECT',

    PRESENT_COLLECT = 'PRESENT_COLLECT',
    PRESENT_BERRIES_APPLY = 'PRESENT_BERRIES_APPLY',
    PRESENT_BANANA_APPLY = 'PRESENT_BANANA_APPLY',
    PRESENT_CITRUS_APPLY = 'PRESENT_CITRUS_APPLY',
    PRESENT_GRAPES_APPLY = 'PRESENT_GRAPES_APPLY',

    MOVE = 'MOVE',

    MUSIC_BACKGROUND = 'MUSIC_BACKGROUND',
    GAME_OVER = 'GAME_OVER',
}

export const sounds: Record<SoundKey, Howl | Howl[]> = {
    [SoundKey.CLICK]: new Howl({
        src: '/sounds/Knop 3.mp3',
        preload: true,
    }),
    [SoundKey.QUESTION_CORRECT]: new Howl({
        src: '/sounds/Goed antwoord 2.mp3',
        preload: true,
    }),
    [SoundKey.QUESTION_WRONG]: new Howl({
        src: '/sounds/Fout antwoord 2.mp3',
        preload: true,
    }),
    [SoundKey.PRESENT_COLLECT]: new Howl({
        src: '/sounds/presents/Collect Present.mp3',
        preload: true,
    }),
    [SoundKey.PRESENT_BERRIES_APPLY]: new Howl({
        src: '/sounds/presents/Powerup Bessen.mp3',
        preload: true,
    }),
    [SoundKey.PRESENT_BANANA_APPLY]: [
        new Howl({
            src: '/sounds/presents/Powerup Banaan 1.mp3',
            preload: true,
        }),
        new Howl({
            src: '/sounds/presents/Powerup Banaan 2.mp3',
            preload: true,
        }),
    ],
    [SoundKey.PRESENT_CITRUS_APPLY]: [
        new Howl({
            src: '/sounds/presents/Powerup Citroen 1.mp3',
            preload: true,
        }),
        new Howl({
            src: '/sounds/presents/Powerup Citroen 2.mp3',
            preload: true,
        }),
    ],
    [SoundKey.PRESENT_GRAPES_APPLY]: new Howl({
        src: '/sounds/presents/Powerup Druiven.mp3',
        preload: true,
    }),
    [SoundKey.MOVE]: new Howl({
        src: '/sounds/Vooruit 1.mp3',
        preload: true,
    }),
    [SoundKey.MUSIC_BACKGROUND]: [
        new Howl({
            src: '/sounds/music/Achtergrond muziek 1.mp3',
            preload: false,
        }),
        new Howl({
            src: '/sounds/music/Achtergrond muziek 2.mp3',
            preload: false,
        }),
        new Howl({
            src: '/sounds/music/Achtergrond muziek 3.mp3',
            preload: false,
        }),
        new Howl({
            src: '/sounds/music/Achtergrond muziek 4.mp3',
            preload: false,
        }),
        new Howl({
            src: '/sounds/music/Achtergrond muziek 5.mp3',
            preload: false,
        }),
        new Howl({
            src: '/sounds/music/Achtergrond muziek 6.mp3',
            preload: false,
        }),
    ],
    [SoundKey.GAME_OVER]: new Howl({
        src: '/sounds/music/Einde potje.mp3',
        preload: false,
    }),
};
