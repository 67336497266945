import React, { useEffect, useState } from 'react';
import { DebugInfo } from '../types/DebugInfo';
import BackendApiService from '../services/BackendApiService';
import { Skeleton } from './Skeleton';
import { DefaultButton } from './DefaultButton';

export const DebugScreen: React.FC = () => {
    const [shown, setShown] = useState(false);
    const [debugData, setDebugData] = useState<DebugInfo | undefined>(undefined);
    const [loadInterval, setLoadInterval] = useState<NodeJS.Timeout | undefined>(undefined);

    const updateDebugInfo = () => BackendApiService.getDebugInfo().then(setDebugData);

    useEffect(() => {
        const handle = (e: KeyboardEvent) => {
            if (e.code === 'Backquote' && (e.ctrlKey || e.metaKey) && e.altKey && e.shiftKey) {
                setShown((p) => !p);
            }
        };

        const query = new URLSearchParams(window.location.search);
        if (query.get('debug') === 'true') setShown(true);

        window.addEventListener('keydown', handle);
        return () => window.removeEventListener('keydown', handle);
    }, []);

    useEffect(() => {
        if (loadInterval) clearInterval(loadInterval);
        if (!shown) return;

        const interval = setInterval(updateDebugInfo, 1000);
        setLoadInterval(interval);
        return () => clearInterval(interval);
    }, [shown]);

    useEffect(() => {
        if (!shown) return;
        setDebugData(undefined);
        updateDebugInfo().then();
    }, [shown]);

    const resetElo = () => {
        if (confirm('Weet je zeker dat je je account wilt resetten? Je zal al je voortgang verliezen!'))
            BackendApiService.resetElo().then(() => location.reload());
    };

    if (!shown) return null;

    return (
        <div className="pointer-events-none fixed inset-0 z-[1000] h-screen w-full p-8">
            <div className="pointer-events-auto flex max-w-md select-text flex-col gap-4 rounded-xl border border-neutral-600 bg-neutral-800/95 p-4 text-left text-white shadow-2xl backdrop-blur">
                <h3 className="flex w-full items-center justify-between text-xl font-semibold">
                    Semsom Rekenrace Debug Menu
                    <button onClick={() => setShown(false)} className="ml-auto">
                        &#x02A2F;
                    </button>
                </h3>

                {!debugData ? (
                    <Skeleton className="h-36 w-full bg-neutral-600" />
                ) : (
                    <div className="font-mono">
                        <p>
                            User ID: <span className="font-semibold">{debugData.id}</span>
                        </p>
                        <p>
                            Basispoort ID: <span className="font-semibold">{debugData.userUid}</span>
                        </p>
                        <p>
                            Elo: <span className="font-semibold">{debugData.elo}</span>
                        </p>
                        <p>
                            Max drempel ID: <span className="font-semibold">{debugData.maxDrempelId}</span>
                        </p>
                        <p>
                            Average win rate: <span className="font-semibold">{(debugData.averageWinRate * 100).toFixed()}%</span>
                        </p>
                        <p>
                            Relative average answer time:{' '}
                            <span className="font-semibold">{(debugData.relativeAverageAnswerTime * 100).toFixed()}%</span>
                        </p>
                        <p>
                            Completed exercise count: <span className="font-semibold">{debugData.completedExerciseCount}</span>
                        </p>
                    </div>
                )}

                <div className="flex flex-wrap gap-2">
                    <DefaultButton text={'Reset Account'} className="m-0 !min-w-0 p-1 px-2.5 !text-base" onClick={resetElo} />
                </div>
            </div>
        </div>
    );
};
