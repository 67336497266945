import { Config } from '@repo/common';

export default class CharacterService {
    static mulberry32(a: number) {
        let t = (a += 0x6d2b79f5);
        t = Math.imul(t ^ (t >>> 15), t | 1);
        t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
        return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    }

    static seedStringToNumber(seed: string) {
        let hash = 0;
        for (let i = 0; i < seed.length; i++) {
            hash = (hash << 5) - hash + seed.charCodeAt(i);
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    static getCharacterName(roomId: string, character: string, existingCharacterNames: string[]) {
        const seed = this.seedStringToNumber(`${roomId}-${character}`);
        const random = this.mulberry32(seed);

        const availableNames = Config.CHARACTER_NAMES.filter((name) => !existingCharacterNames.includes(name));
        return availableNames[Math.floor(random * availableNames.length)];
    }
}
