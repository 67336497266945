"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeItem = exports.flatten = exports.sleep = exports.minmax = void 0;
const minmax = (min, max, value) => Math.max(min, Math.min(max, value));
exports.minmax = minmax;
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
exports.sleep = sleep;
const flatten = (arr) => arr.reduce((a, b) => a.concat(b), []);
exports.flatten = flatten;
const removeItem = (arr, value) => {
    const index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
};
exports.removeItem = removeItem;
