import { Config, sleep } from '@repo/common';

type FrameRendererData = {
    totalFrames: number;
};
type FrameRenderer = (frame: number, data: FrameRendererData) => Promise<void> | void;

export default class GTAnimation {
    private readonly duration: number;
    private readonly frameRenderer: FrameRenderer;

    constructor({ duration, frameRenderer }: { duration: number; frameRenderer: FrameRenderer }) {
        this.frameRenderer = frameRenderer;
        this.duration = duration;
    }

    public async play() {
        const frames = (this.duration / 1000) * Config.CUSTOM_ANIMATION_FPS;

        for (let i = 0; i < frames; i++) {
            const frameStart = Date.now();

            try {
                const result = this.frameRenderer(i, {
                    totalFrames: frames,
                });

                if (result instanceof Promise) {
                    await result;
                }
            } catch (e) {
                console.warn(`Animation cancelled: ${e instanceof Error ? e.message : e}`);
                return;
            }

            const frameEnd = Date.now();

            await sleep(this.duration / frames - (frameEnd - frameStart));
        }
    }
}
