"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OP_CODE = void 0;
var OP_CODE;
(function (OP_CODE) {
    OP_CODE[OP_CODE["LOGIN_REQUEST"] = 0] = "LOGIN_REQUEST";
    OP_CODE[OP_CODE["LOGIN_RESULT"] = -1] = "LOGIN_RESULT";
    OP_CODE[OP_CODE["PING_RESULT"] = -3] = "PING_RESULT";
    OP_CODE[OP_CODE["UDP_CONNECT"] = -5] = "UDP_CONNECT";
    OP_CODE[OP_CODE["UDP_CONNECT_SERVER_ACK"] = -6] = "UDP_CONNECT_SERVER_ACK";
    OP_CODE[OP_CODE["UDP_CONNECT_CLIENT_ACK"] = -7] = "UDP_CONNECT_CLIENT_ACK";
    OP_CODE[OP_CODE["PLAYER_READY"] = -8] = "PLAYER_READY";
    OP_CODE[OP_CODE["JOIN_GROUP"] = -10] = "JOIN_GROUP";
    OP_CODE[OP_CODE["LEAVE_GROUP"] = -11] = "LEAVE_GROUP";
    OP_CODE[OP_CODE["REQUEST_GROUP_MEMBERSHIP"] = -12] = "REQUEST_GROUP_MEMBERSHIP";
    OP_CODE[OP_CODE["GROUP_MEMBERSHIP_UPDATE"] = -13] = "GROUP_MEMBERSHIP_UPDATE";
    OP_CODE[OP_CODE["VERIFY_IDENTITY"] = -14] = "VERIFY_IDENTITY";
    OP_CODE[OP_CODE["VERIFY_IDENTITY_RESULT"] = -15] = "VERIFY_IDENTITY_RESULT";
    OP_CODE[OP_CODE["PLAYER_CONNECT"] = 113] = "PLAYER_CONNECT";
    OP_CODE[OP_CODE["PLAYER_DISCONNECT"] = 114] = "PLAYER_DISCONNECT";
    OP_CODE[OP_CODE["ROOMS_UPDATE"] = 115] = "ROOMS_UPDATE";
    OP_CODE[OP_CODE["JOIN_ROOM"] = 116] = "JOIN_ROOM";
    OP_CODE[OP_CODE["CURRENT_ROOM_UPDATE"] = 117] = "CURRENT_ROOM_UPDATE";
    OP_CODE[OP_CODE["GAME_POSITION_UPDATE"] = 118] = "GAME_POSITION_UPDATE";
    OP_CODE[OP_CODE["GAME_COLLECT_PRESENT"] = 119] = "GAME_COLLECT_PRESENT";
    OP_CODE[OP_CODE["GAME_PRESENT_EFFECT"] = 120] = "GAME_PRESENT_EFFECT";
    OP_CODE[OP_CODE["GAME_OVER"] = 121] = "GAME_OVER";
    OP_CODE[OP_CODE["UPDATE_CHARACTER"] = 122] = "UPDATE_CHARACTER";
    OP_CODE[OP_CODE["LEAVE_ROOM"] = 123] = "LEAVE_ROOM";
    OP_CODE[OP_CODE["CREATE_SINGLE_PLAYER_ROOM"] = 124] = "CREATE_SINGLE_PLAYER_ROOM";
})(OP_CODE || (exports.OP_CODE = OP_CODE = {}));
