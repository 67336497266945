import { TwoColumnGrid } from '../../../shared/components/TwoColumnGrid';
import { RoomSelectorOverview } from '../shared/components/RoomSelectorOverview';
import { LogoSection } from '../../../shared/components/LogoSection';
import { WoodenBackground } from '../../../shared/components/WoodenBackground';
import { LeaveButton } from '../../../shared/components/LeaveButton';
import BackendApiService from '../../../shared/services/BackendApiService';

export const RoomSelect = () => {
    const leaveGame = () => (window.location.href = BackendApiService.buildLogoutUrl());

    return (
        <TwoColumnGrid leftColumnSizeMobile="30%" rightColumnSizeMobile="70%" leftColumnSize="50%" rightColumnSize="50%" height="100dvh">
            <LogoSection />

            <div className="relative h-full  p-4 py-6 lg:h-screen">
                <LeaveButton
                    onClick={leaveGame}
                    text="Spel afsluiten"
                    icon={
                        <svg className="aspect-square h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path d="M352 416l64 0c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c53 0 96 43 96 96l0 256c0 53-43 96-96 96l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32zm-217.9-9.9L11.3 283.3C4.1 276.1 0 266.2 0 256s4.1-20.1 11.3-27.3L134.1 105.9c6.4-6.4 15-9.9 24-9.9c18.7 0 33.9 15.2 33.9 33.9l0 62.1 128 0c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32l-128 0 0 62.1c0 18.7-15.2 33.9-33.9 33.9c-9 0-17.6-3.6-24-9.9z" />
                        </svg>
                    }
                />

                <WoodenBackground />

                <div className="flex h-full flex-col items-center justify-center gap-10 lg:gap-20" data-testid="room-select">
                    <div className="flex flex-col items-center gap-2 lg:gap-4">
                        <h2
                            className="text-pretty text-xl font-bold text-white drop-shadow-solidGray lg:text-4xl"
                            data-testid="room-select-title"
                        >
                            Speel online met klasgenoten
                        </h2>
                        <p className="text-pretty text-base text-white drop-shadow-sm lg:text-2xl" data-testid="room-select-description">
                            Kies samen een plek om naar toe te gaan!
                        </p>
                    </div>

                    <div className="flex flex-col gap-12 lg:gap-48">
                        <RoomSelectorOverview />
                    </div>
                </div>
            </div>
        </TwoColumnGrid>
    );
};
