import { useEffect, useRef, useState } from 'react';
import { cn } from '../utils';

interface LoadBarProps {
    timerEnded?: () => void;
    duration: number;
    className?: string;
}

export const LoadBar = (props: LoadBarProps) => {
    const [progress, setProgress] = useState(100);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const startRef = useRef<number>(Date.now());

    const runTimer = () => {
        startRef.current = Date.now();

        const updateProgress = () => {
            const elapsedTime = Date.now() - startRef.current;
            const remainingTime = props.duration - elapsedTime;
            const newProgress = Math.max((remainingTime / props.duration) * 100, 0);

            setProgress(newProgress);

            if (elapsedTime < props.duration) {
                intervalRef.current = setTimeout(() => {
                    requestAnimationFrame(updateProgress);
                }, 100);
            } else {
                endTimer();
                if (props.timerEnded) props.timerEnded();
            }
        };

        requestAnimationFrame(updateProgress);
    };

    const endTimer = () => {
        if (intervalRef.current) {
            clearTimeout(intervalRef.current);
            intervalRef.current = null;
        }
    };

    useEffect(() => {
        runTimer();
        return () => endTimer();
    }, [props.duration]);

    return (
        <div className="w-full">
            <div
                className={cn('h-8 rounded-md bg-gray-900 transition-all duration-200', props.className)}
                style={{ width: `${progress}%` }}
            ></div>
        </div>
    );
};
