"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Config = void 0;
const types_1 = require("@repo/types");
const environment_1 = require("./environment");
// This is the max players that can connect at once.
// Used by the login lambda and GameLift server.
const MAX_CONNECTED_PLAYERS = 1024;
// The time between ticks
const TICK_TIME = 1000;
const ENDPOINTS = {
    GAMELIFT_LOGIN: environment_1.API_URL + '/gamelift/session',
};
const PLAYER_DISCONNECT_TIMEOUT = 10000;
const ROOM_ICONS = [
    '/images/room-icons/1.svg',
    '/images/room-icons/2.svg',
    '/images/room-icons/3.svg',
    '/images/room-icons/4.svg',
    '/images/room-icons/5.svg',
    '/images/room-icons/6.svg',
    '/images/room-icons/7.svg',
    '/images/room-icons/8.svg',
    '/images/room-icons/9.svg',
    '/images/room-icons/10.svg',
    '/images/room-icons/11.svg',
    '/images/room-icons/12.svg',
    '/images/room-icons/13.svg',
    '/images/room-icons/14.svg',
    '/images/room-icons/15.svg',
];
const ROOM_MAX_PLAYERS = 4;
const ROOM_DISPLAY_COUNT = 10;
// The time the players have to pick their characters.
const ROOM_WAIT_TIME = 10000;
// How long the present popup should be shown
const PRESENT_DISPLAY_TIME = 2000;
const PRESENT_EFFECT_OVERLAY_DURATIONS = {
    [types_1.PRESENT_TYPE.BANANA]: 3500,
    [types_1.PRESENT_TYPE.BERRIES]: PRESENT_DISPLAY_TIME + 500,
    [types_1.PRESENT_TYPE.CITRUS]: PRESENT_DISPLAY_TIME,
    [types_1.PRESENT_TYPE.GRAPES]: 3500,
};
const PRESENT_LOCATIONS = [28, 46, 64, 82];
const PLAYER_POSITION_BOUNDS = [10, 100];
// How long to show the congrats or game over screen
const ROOM_GAME_OVER_WAIT_TIME = 5000;
const ROOM_RESULTS_DISPLAY_TIME = 10000;
const CHARACTER_NAMES = [
    'Bas Bes',
    'Bep Boef',
    'Bob Boem',
    'Fik Fop',
    'Flip Flap',
    'Juf Jop',
    'Kas Krul',
    'Kip Kop',
    'Lies Los',
    'Mees Mep',
    'Miep Moef',
    'Mik Mak',
    'Pip Pom',
    'Saar Sok',
    'Sam Snuf',
    'Sem Sap',
    'Tom Tok',
    'Vik Vlak',
    'Wip Wap',
    'Zoem Zoem',
];
const CUSTOM_ANIMATION_FPS = 60;
const MINIMUM_AI_MOVE_WAIT_TIME = 4000;
exports.Config = {
    MAX_CONNECTED_PLAYERS,
    TICK_TIME,
    ENDPOINTS,
    PLAYER_DISCONNECT_TIMEOUT,
    ROOM_ICONS,
    ROOM_MAX_PLAYERS,
    ROOM_DISPLAY_COUNT,
    ROOM_WAIT_TIME,
    PRESENT_DISPLAY_TIME,
    PRESENT_EFFECT_OVERLAY_DURATIONS,
    ROOM_GAME_OVER_WAIT_TIME,
    ROOM_RESULTS_DISPLAY_TIME,
    PRESENT_LOCATIONS,
    CHARACTER_NAMES,
    CUSTOM_ANIMATION_FPS,
    MINIMUM_AI_MOVE_WAIT_TIME,
    PLAYER_POSITION_BOUNDS,
    ENVIRONMENT: environment_1.ENVIRONMENT,
    API_URL: environment_1.API_URL,
};
