import { IMAGES } from '../services/ImageCachingService';
import { RefObject } from 'react';

type CachedImageProps = {
    src: IMAGES;
    alt?: string;
    className?: string;
    ref?: RefObject<HTMLImageElement>;
};

export function CachedImage({ src, alt = '', className, ref }: CachedImageProps) {
    return ref ? <img src={src} alt={alt} className={className} ref={ref} /> : <img src={src} alt={alt} className={className} />;
}

export { IMAGES } from '../services/ImageCachingService';
