import React, { useEffect, useState } from 'react';

import monkeyHappy1 from '../assets/img/monkey-happy-1.png';
import AudioService from '../services/AudioService';
import { CachedImage, IMAGES } from './CachedImage';

export const LogoSection: React.FC = () => {
    const [muted, setMuted] = useState(AudioService.muted);

    useEffect(() => {
        const handler = (isMuted: boolean) => setMuted(isMuted);
        AudioService.onMutedChange.subscribe(handler);
        return () => AudioService.onMutedChange.unsubscribe(handler);
    }, []);

    const toggleMuted = () => AudioService.toggleMute();

    return (
        <>
            <div className="relative z-0 bg-black/75 from-green-950 to-sky-950 lg:bg-gradient-to-t" data-testid="logo-section">
                <div className="invisible absolute left-0 top-0 z-0 size-full lg:visible" data-testid="jungle-background">
                    <CachedImage src={IMAGES.JUNGLE_BACKGROUND} alt="jungle background" className="size-full object-cover" />
                </div>

                <div className="visible absolute left-0 top-0 z-0 size-full lg:invisible">
                    <CachedImage src={IMAGES.RACE_TRACKS} alt="race tracks" className="size-full object-cover" />
                </div>

                <div className="absolute left-0 top-0 z-0 size-full bg-gradient-to-t from-black/75 to-transparent lg:from-black/50"></div>

                <div className="z-20">
                    <div className="mx-auto mb-0 mt-10 w-28 drop-shadow-none md:mb-5 md:w-32 lg:mt-20 lg:w-64" data-testid="logo">
                        <CachedImage src={IMAGES.REKENRACE_LOGO} className="size-full object-contain" alt="logo" />
                    </div>

                    <div className="invisible absolute bottom-16 left-2 w-1/4 max-w-48 drop-shadow-solidGray lg:visible lg:bottom-36 lg:left-4 lg:w-60 lg:max-w-none">
                        <img
                            src={monkeyHappy1}
                            alt="happy monkey"
                            className="w-full object-cover drop-shadow-2xl"
                            data-testid="monkey-image"
                        />
                    </div>

                    <div className="absolute inset-x-0 bottom-0 flex items-center gap-4 p-2 lg:p-4">
                        <div
                            className="w-1/5 min-w-24 max-w-48 place-items-center drop-shadow-solidGray lg:w-48"
                            data-testid="zwijsen-logo"
                        >
                            <CachedImage src={IMAGES.ZWIJSEN_LOGO} alt="zwijsen logo" className="w-full object-cover" />
                        </div>
                    </div>
                </div>

                <button onClick={toggleMuted} className="absolute bottom-0 right-0 z-50 grid size-16 place-items-center fill-white p-4">
                    {muted ? (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-testid="mute-button">
                            <path d="M301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zM425 167l55 55 55-55c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-55 55 55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-55-55-55 55c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l55-55-55-55c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-testid="unmute-button">
                            <path d="M333.1 34.8C344.6 40 352 51.4 352 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L163.8 352H96c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L298.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zm172 72.2c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C507.3 341.3 528 301.1 528 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C466.1 199.1 480 225.9 480 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C425.1 284.4 432 271 432 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5z" />
                        </svg>
                    )}
                </button>
            </div>
        </>
    );
};
