import { TwoColumnGrid } from '../../../shared/components/TwoColumnGrid';
import { DefaultButton } from '../../../shared/components/DefaultButton';
import { useRoom } from '../../../shared/services/RoomContext';
import { Character } from '../shared/components/Character';
import { WoodenBackground } from '../../../shared/components/WoodenBackground';
import { GamePlayer, OP_CODE } from '@repo/types';
import GameLiftService from '../../../shared/services/GameLiftService';
import { Navigate } from 'react-router-dom';
import { NameTag } from '../../../shared/components/NameTag';
import { CachedImage, IMAGES } from '../../../shared/components/CachedImage';
import { WinText } from '../shared/components/WinText';
import { useRef, useState } from 'react';
import { Config } from '@repo/common';
import { LoadBar } from '../../../shared/components/LoadBar';
import { TimerButton } from '../../../shared/components/TimerButton';
import { useGame } from '../../../shared/services/GameContext';
// import { Config } from '@repo/common';
// import { LoadBar } from '../../../shared/components/LoadBar';

interface Pillar {
    number: number;
    player: GamePlayer;
    size: string;
}

const pillarHeights: Record<number, string> = {
    0: 'h-20 lg:h-40',
    1: 'h-16 lg:h-28',
    2: 'h-10 lg:h-16',
    3: 'h-0',
};

export const Result = () => {
    const { room, currentPlayer, characterNames } = useRoom();
    const { isMobile } = useGame();
    const roomStartsAt = useRef(Date.now() + Config.ROOM_RESULTS_DISPLAY_TIME);

    if (!room) {
        console.error('Room not found');
        return <Navigate to="/room-select" />;
    }

    const [clickedPlayAgain, setClickedPlayAgain] = useState(false);

    room.players.sort((a, b) => b.position - a.position);

    const pillars: Pillar[] = room.players.map((player, idx) => ({
        number: idx + 1,
        player: player,
        size: pillarHeights[idx],
    }));

    pillars.sort((a, b) => a.player.track_index - b.player.track_index);

    const leaveRoom = async () => {
        const client = await GameLiftService.client;
        client.send(client.newMessage(OP_CODE.LEAVE_ROOM, {}));
    };

    return (
        <>
            <TwoColumnGrid
                leftColumnSizeMobile="50%"
                rightColumnSizeMobile="50%"
                leftColumnSize="70%"
                rightColumnSize="30%"
                height="100dvh"
            >
                <div className="relative" data-testid="result-view">
                    <div className="absolute left-0 top-0 -z-10 size-full" data-testid="jungle-background">
                        <CachedImage src={IMAGES.JUNGLE_BACKGROUND} alt="race tracks" className="size-full object-cover" />
                    </div>

                    <div
                        className="absolute bottom-[10vh] right-0 flex w-full items-end justify-center lg:bottom-[20vh]"
                        data-testid="pillars"
                    >
                        {pillars.map((pillar, idx) => (
                            <div
                                key={'pillar-' + idx}
                                className={'relative flex h-fit w-full flex-col items-center justify-end gap-2 p-2'}
                                style={{
                                    maxWidth: `min(${100 / room.players.length - 2.5}%, 12rem)`,
                                }}
                                data-testid={`pillar`}
                            >
                                <div className="relative size-full p-4 pb-0" data-testid={`character-${pillar.player.character}`}>
                                    <Character className="drop-shadow-solidGray" character={pillar.player.character} />

                                    {!isMobile ? (
                                        <NameTag
                                            name={characterNames[pillar.player.character]}
                                            className="absolute -bottom-4 left-1/2 -translate-x-1/2 lg:-bottom-8"
                                        />
                                    ) : null}
                                </div>

                                {pillar.number !== 4 ? (
                                    <>
                                        <img src={`/images/podium-${pillar.number}.svg`} alt="podium" className={` size-full`} />
                                    </>
                                ) : null}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="relative grid h-full place-items-center lg:h-screen" data-testid="result-details">
                    <div className="w-full max-w-sm">
                        <WoodenBackground />

                        <div className="flex w-full flex-col items-center justify-center gap-8 p-4">
                            <>
                                {currentPlayer?.position === 100 ? (
                                    <div className="flex w-full flex-col gap-4 font-bold text-white" data-testid="winning-message">
                                        <h1 className="text-6xl drop-shadow-solidGray">🏆</h1>
                                        <h2 className="text-4xl font-semibold drop-shadow-solidGray">Gewonnen!</h2>
                                        <h3 className="text-2xl drop-shadow-sm">
                                            <WinText page="result" />
                                        </h3>
                                    </div>
                                ) : (
                                    <div className="flex w-full flex-col gap-4 text-white" data-testid="try-again-message">
                                        <h1 className="text-6xl drop-shadow-solidGray">👍</h1>
                                        <h2 className="text-4xl font-semibold drop-shadow-solidGray">Goed geprobeerd!</h2>
                                        <h3 className="text-2xl drop-shadow-sm">Volgende keer beter</h3>
                                    </div>
                                )}

                                <div className="flex w-full flex-col gap-4">
                                    <span data-testid="leave-room-button">
                                        {!clickedPlayAgain ? <DefaultButton className="w-full" text="Stoppen" onClick={leaveRoom} /> : null}
                                    </span>

                                    <div data-testid="play-again-section">
                                        {clickedPlayAgain ? (
                                            <div>
                                                <p className="text-2xl font-semibold text-white drop-shadow-solidGray">Maak je klaar!</p>

                                                <div
                                                    data-testid="load-bar"
                                                    className="mx-auto mt-4 h-8 rounded-lg bg-gray-800/90 shadow-solidGray"
                                                >
                                                    <LoadBar
                                                        className="w-full bg-yellow-400"
                                                        duration={roomStartsAt.current - Date.now()}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <TimerButton
                                                    duration={Config.ROOM_RESULTS_DISPLAY_TIME}
                                                    className="w-full"
                                                    text="Nog een keer!"
                                                    onClick={() => setClickedPlayAgain(true)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </TwoColumnGrid>
        </>
    );
};
