import { Config } from '@repo/common';

export enum IMAGES {
    ZWIJSEN_LOGO = '/images/zwijsen-logo.webp',
    REKENRACE_LOGO = '/images/rekenrace-logo-3.png',
    JUNGLE_BACKGROUND = '/images/jungle-background.webp',
    RACE_TRACKS = '/images/race-tracks-small.webp',
    WOODEN_BACKGROUND = '/images/wooden-background.webp',
    PRESENT_SHINE = '/images/present-shine.webp',
    ROOM_SIGN = '/images/room-sign.svg',
    ZANDLOPER = '/images/zandloper.webp',

    OVERLAY_GRAPES_1 = '/images/overlay/grapes/1.svg',
    OVERLAY_GRAPES_2 = '/images/overlay/grapes/2.svg',
    OVERLAY_GRAPES_3 = '/images/overlay/grapes/3.svg',

    OVERLAY_CITRUS_1 = '/images/overlay/citrus/1.svg',
    OVERLAY_CITRUS_2 = '/images/overlay/citrus/2.svg',
    OVERLAY_CITRUS_3 = '/images/overlay/citrus/3.svg',

    PRESENT_TYPE_BANANA = '/images/power-ups/banana.webp',
    PRESENT_TYPE_BERRIES = '/images/power-ups/berries.webp',
    PRESENT_TYPE_CITRUS = '/images/power-ups/citrus.svg',
    PRESENT_TYPE_GRAPES = '/images/power-ups/grapes.svg',

    CHARACTER_PRESENT_TYPE_BANANA = `/images/character-power-ups/banana.webp`,
    CHARACTER_PRESENT_TYPE_CITRUS = `/images/character-power-ups/citrus.svg`,
    CHARACTER_PRESENT_TYPE_GRAPES = `/images/character-power-ups/grapes.svg`,

    CHARACTERS_1 = '/images/characters/1.webp',
    CHARACTERS_2 = '/images/characters/2.webp',
    CHARACTERS_3 = '/images/characters/3.webp',
    CHARACTERS_4 = '/images/characters/4.webp',
    CHARACTERS_5 = '/images/characters/5.webp',
    CHARACTERS_6 = '/images/characters/6.webp',
    CHARACTERS_7 = '/images/characters/7.webp',
    CHARACTERS_8 = '/images/characters/8.webp',

    CHARACTERS_1_CROPPED = '/images/characters-cropped/1.webp',
    CHARACTERS_2_CROPPED = '/images/characters-cropped/2.webp',
    CHARACTERS_3_CROPPED = '/images/characters-cropped/3.webp',
    CHARACTERS_4_CROPPED = '/images/characters-cropped/4.webp',
    CHARACTERS_5_CROPPED = '/images/characters-cropped/5.webp',
    CHARACTERS_6_CROPPED = '/images/characters-cropped/6.webp',
    CHARACTERS_7_CROPPED = '/images/characters-cropped/7.webp',
    CHARACTERS_8_CROPPED = '/images/characters-cropped/8.webp',

    FLYING_BERRY_1 = '/images/flying-berries/1.webp',
    FLYING_BERRY_2 = '/images/flying-berries/2.webp',
    FLYING_BERRY_3 = '/images/flying-berries/3.webp',

    BACKGROUND_ANSWERS = '/images/background-answers.svg',
    BACKGROUND_QUESTIONS = '/images/background-question.svg',
}

const IMAGES_TO_CACHE = [...Object.values(IMAGES), ...Config.ROOM_ICONS] as const;

export default class ImageCachingService {
    static load(src: string) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = resolve;
            img.onerror = reject;
            img.src = src;
        });
    }

    static init() {
        return Promise.allSettled(Object.values(IMAGES_TO_CACHE).map((i) => this.load(i)));
    }
}

ImageCachingService.init().then();
