import { Question, QuestionAnswer } from '../types/Question';
import { Config } from '@repo/common';
import { DebugInfo } from '../types/DebugInfo';
import { Profiel } from '../types/Profiel';

export default class BackendApiService {
    private static baseUrl = Config.API_URL;

    static async getExercises(positionInRace: number): Promise<Question[]> {
        const response = await fetch(`${this.baseUrl}/exercises?position=${positionInRace}`, {
            credentials: 'include',
            cache: 'no-cache',
            headers: {
                accept: 'application/json',
            },
        });

        if (response.ok) {
            return await response.json();
        }

        throw new Error(response.statusText);
    }

    static async answerExercises(answers: QuestionAnswer[]): Promise<void> {
        const response = await fetch(`${this.baseUrl}/exercises/answer`, {
            method: 'POST',
            credentials: 'include',
            cache: 'no-cache',
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(answers),
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }
    }

    static async finishRace(won: boolean) {
        const response = await fetch(`${this.baseUrl}/exercises/finish`, {
            method: 'POST',
            credentials: 'include',
            cache: 'no-cache',
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ won }),
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }
    }

    static async getProfiel(): Promise<Profiel> {
        const response = await fetch(`${this.baseUrl}/profiel`, {
            credentials: 'include',
            cache: 'no-cache',
            headers: {
                accept: 'application/json',
            },
        });

        if (response.ok) {
            return await response.json();
        }

        throw new Error(response.statusText);
    }

    static async resetElo() {
        const response = await fetch(`${this.baseUrl}/profiel/reset-elo`, {
            method: 'POST',
            credentials: 'include',
            cache: 'no-cache',
            headers: {
                accept: 'application/json',
            },
        });

        if (response.ok) {
            return await response.json();
        }

        throw new Error(response.statusText);
    }

    static async getDebugInfo(): Promise<DebugInfo> {
        const response = await fetch(`${this.baseUrl}/profiel/debug`, {
            credentials: 'include',
            cache: 'no-cache',
            headers: {
                accept: 'application/json',
            },
        });

        if (response.ok) {
            return await response.json();
        }

        throw new Error(response.statusText);
    }

    static buildLoginUrl(redirectUrl: string) {
        return `${this.baseUrl}/auth/login?idp_identifier=basispoort&returnUrl=${encodeURIComponent(redirectUrl)}`;
    }

    static buildLogoutUrl() {
        return `${this.baseUrl}/auth/logout`;
    }
}
