import { useCallback, useEffect, useRef, useState } from 'react';
import { GameView } from '../shared/components/GameView';
import { TwoColumnGrid } from '../../../shared/components/TwoColumnGrid';
import { Keyboard } from '../shared/components/Keyboard';
import { Question, QuestionAnswer } from '../../../shared/types/Question';
import { useGame } from '../../../shared/services/GameContext';
import { LoadBar } from '../../../shared/components/LoadBar';
import { WoodenBackground } from '../../../shared/components/WoodenBackground';
import GameLiftService from '../../../shared/services/GameLiftService';
import { OP_CODE, PRESENT_TYPE, PresentEffect, RoomState } from '@repo/types';
import { RTMessage } from '@repo/gamelift-client';
import { useRoom } from '../../../shared/services/RoomContext';
import { Config, sleep } from '@repo/common';
import { presentImages, SoundKey } from '../../../shared/data';
import { cn } from '../../../shared/utils';

import AudioService from '../../../shared/services/AudioService';
import { LeaveButton } from '../../../shared/components/LeaveButton';
import BackendApiService from '../../../shared/services/BackendApiService';
import { CachedImage, IMAGES } from '../../../shared/components/CachedImage';
import { WinText } from '../shared/components/WinText';
import { SimpleEventDispatcher } from 'strongly-typed-events';
import QuestionService from '../shared/services/questions';

export const Game = () => {
    const { animateMoveScreenView, isMobile } = useGame();
    const { currentPlayer, room, leaveRoom } = useRoom();

    const [countDown, setCountDown] = useState<number>((!room ? false : Date.now() > new Date(room!.starts_at).getTime() + 5000) ? 0 : 3);
    const [questions, setQuestions] = useState<Question[]>([]);

    const [currentQuestion, setCurrentQuestion] = useState<Question | null>();
    const [givenAnswer, setGivenAnswer] = useState<string>('');
    const [newQuestionAt, setNewQuestionAt] = useState<number>(0);

    const [displayPresent, setDisplayPresent] = useState<boolean>(false);
    const [lastPresent, setLastPresent] = useState<PRESENT_TYPE | undefined>(undefined);
    const [showGrapesOverlay, setShowGrapesOverlay] = useState<boolean>(false);
    const [showCitrusOverlay, setShowCitrusOverlay] = useState<boolean>(false);
    const [playerPresentEffects, setPlayerPresentEffects] = useState<Record<string, Record<PRESENT_TYPE, boolean>>>({});
    const [inputsDisabled, setInputsDisabled] = useState<boolean>(false);
    const [presentLocations, setPresentLocations] = useState<number[]>(Config.PRESENT_LOCATIONS);
    const [blurQuestion, setBlurQuestion] = useState<number>(0);
    const [cachedAnswers, setCachedAnswers] = useState<QuestionAnswer[]>([]);
    const [banana, setBanana] = useState(false);

    // The actual percent the player is going to move of the max
    const currentMovePercent = useRef<number>(100);
    const currentMovePercentInterval = useRef<ReturnType<typeof setInterval> | undefined>(undefined);
    const [indicatorHeight, setIndicatorHeight] = useState<number>(currentMovePercent.current);

    const [previousAnswers, setPreviousAnswers] = useState<
        {
            id: string;
            text: string;
            givenAnswer: string;
            isCorrect: boolean;
        }[]
    >([]);

    const [onPresentEffect] = useState(new SimpleEventDispatcher<PresentEffect>());

    const grapesOverlayRef1 = useRef<HTMLImageElement>(null);
    const grapesOverlayRef2 = useRef<HTMLImageElement>(null);
    const grapesOverlayRef3 = useRef<HTMLImageElement>(null);

    const citrusOverlayRef1 = useRef<HTMLImageElement>(null);
    const citrusOverlayRef2 = useRef<HTMLImageElement>(null);
    const citrusOverlayRef3 = useRef<HTMLImageElement>(null);

    const currentQuestionRef = useRef<HTMLDivElement>(null);

    const startCurrentMovePercentInterval = useCallback(() => {
        if (currentMovePercentInterval.current !== undefined) {
            clearInterval(currentMovePercentInterval.current);
        }

        currentMovePercent.current = 100;
        setIndicatorHeight(currentMovePercent.current);

        const intervalId = setInterval(
            () => {
                const newPercent = Math.max(currentMovePercent.current - 1, 0);
                if (newPercent <= 0) {
                    clearInterval(intervalId);
                    currentMovePercentInterval.current = undefined;
                }

                currentMovePercent.current = newPercent;
                setIndicatorHeight(currentMovePercent.current);
            },
            (currentQuestion?.givenAnswerTime ?? 5) * 10,
        );

        currentMovePercentInterval.current = intervalId;
    }, [currentQuestion?.givenAnswerTime]);

    useEffect(() => {
        if (countDown > 0) {
            const timeoutId = setTimeout(() => {
                setCountDown(countDown - 1);

                if (countDown === 1) {
                    startCurrentMovePercentInterval();
                }
            }, 1000);

            return () => clearTimeout(timeoutId);
        }

        animateMoveScreenView();
    }, [countDown, animateMoveScreenView, startCurrentMovePercentInterval]);

    useEffect(() => {
        return () => {
            if (currentMovePercentInterval !== undefined) {
                clearInterval(currentMovePercentInterval.current);
            }
        };
    }, [currentMovePercentInterval]);

    const handleInput = async (value: string) => {
        if (!currentQuestion?.answer) return;

        if (value === '<') {
            setGivenAnswer(givenAnswer.slice(0, -1));
            return;
        }

        const regex = /^[0-9]$/;
        if (!regex.test(value) && value !== '') {
            return;
        }

        const newValue = (givenAnswer + value).slice(0, currentQuestion.answer.toString().length);
        if (givenAnswer === newValue) return;

        setGivenAnswer(newValue);

        if (newValue.length === currentQuestion.answer.toString().length) {
            await checkAnswer(newValue);
        }
    };

    const getPositionInRace = () => {
        room?.players.sort((a, b) => b.position - a.position);
        return room?.players.findIndex((p) => p.id === currentPlayer?.id) ?? 0;
    };

    const checkAnswer = async (answer: string) => {
        const isCorrect = currentQuestion!.answer.trim() === answer.trim();

        const questionAnswer: QuestionAnswer = {
            id: currentQuestion!.id,
            answer: answer,
            answerTime: (Date.now() - newQuestionAt) / 1000,
        };

        BackendApiService.answerExercises([...cachedAnswers, questionAnswer])
            .then(() => {
                setCachedAnswers([]);
                BackendApiService.getExercises(getPositionInRace()).then((q) => setQuestions(q));
            })
            .catch(() => {
                setCachedAnswers((prev) => [...prev, questionAnswer]);
            });

        const moveDistance = (currentQuestion!.answerMoveDistance / 100) * currentMovePercent.current;

        if (currentMovePercentInterval.current !== undefined) {
            clearInterval(currentMovePercentInterval.current);
        }

        await sleep(250);

        QuestionService.onAnswer.dispatch(isCorrect);

        if (currentQuestionRef.current) currentQuestionRef.current.style.color = isCorrect ? '#92BA3D' : '#E14C56';
        if (isCorrect) AudioService.play(SoundKey.QUESTION_CORRECT, 0.1);
        else AudioService.play(SoundKey.QUESTION_WRONG, 0.25);

        // Time for the berry to fly to the player
        await sleep(isCorrect ? 625 : 350);

        if (currentQuestionRef.current) currentQuestionRef.current.style.color = 'black';

        setGivenAnswer('');
        setPreviousAnswers((prev) =>
            [
                ...prev,
                {
                    id: crypto.randomUUID(),
                    text: currentQuestion!.text!,
                    givenAnswer: answer,
                    isCorrect,
                },
            ].slice(-4),
        );

        if (isCorrect) {
            const [hasCollectedPresent, newPosition] = await move(moveDistance);
            if (hasCollectedPresent) {
                await sleep(250);
            }

            // We don't un-blur the question if we won
            if (newPosition < 100) {
                setTimeout(() => setBlurQuestion((prev) => prev - 1), 250);
            }
        } else {
            setTimeout(() => setBlurQuestion((prev) => prev - 1), 250);
        }

        setBlurQuestion((prev) => prev + 1);
        const currentQuestionIndex = questions.findIndex((q) => q.id === currentQuestion!.id);
        setCurrentQuestion(questions[currentQuestionIndex === questions.length - 1 ? 0 : currentQuestionIndex + 1]);
        setNewQuestionAt(Date.now());

        setTimeout(() => startCurrentMovePercentInterval(), isCorrect ? 450 : 0);
    };

    const collectPresent = async () => {
        const client = await GameLiftService.client;
        client.send(client.newMessage(OP_CODE.GAME_COLLECT_PRESENT, {}));
        AudioService.play(SoundKey.PRESENT_COLLECT, 0.5);
    };

    /**
     * @param distance The distance to move
     * @returns Whether the player has collected a present or won
     */
    const move = useCallback(
        async (distance: number): Promise<[boolean, number]> => {
            const client = await GameLiftService.client;
            const newPlayerLocation = currentPlayer!.position + distance;
            let collectedPresent = false;

            // No more than one present can be collected at once
            const present = presentLocations.find((presentLocation) => newPlayerLocation >= presentLocation);
            if (present) {
                setPresentLocations(presentLocations.filter((presentLocation) => presentLocation > newPlayerLocation));
                await collectPresent();
                collectedPresent = true;
            }

            client.send(
                client.newMessage(OP_CODE.GAME_POSITION_UPDATE, {
                    position: newPlayerLocation,
                }),
            );

            AudioService.play(SoundKey.MOVE, 0.25);

            sleep(500).then(() => AudioService.stop(SoundKey.MOVE));

            return [collectedPresent, newPlayerLocation];
        },
        [currentPlayer, presentLocations],
    );

    const startGrapeAnimation = async () => {
        const fadeInKeyframes = [
            {
                transform: 'translateY(0)',
                scale: 0,
                opacity: 0,
            },
            {
                transform: 'translateY(0)',
                scale: 1,
                opacity: 1,
            },
        ];

        grapesOverlayRef1.current?.animate(fadeInKeyframes, {
            duration: Math.random() * 500,
            fill: 'forwards',
        });

        grapesOverlayRef2.current?.animate(fadeInKeyframes, {
            duration: Math.random() * 500,
            fill: 'forwards',
        });

        grapesOverlayRef3.current?.animate(fadeInKeyframes, {
            duration: Math.random() * 500,
            fill: 'forwards',
        });

        await sleep(Config.PRESENT_EFFECT_OVERLAY_DURATIONS[PRESENT_TYPE.GRAPES]);

        const animateDownKeyframes = [
            {
                transform: 'translateY(0)',
            },
            {
                transform: 'translateY(20%)',
                opacity: 0,
            },
        ];

        grapesOverlayRef1.current?.animate(animateDownKeyframes, {
            duration: 1600,
            delay: 100,
            fill: 'forwards',
            easing: 'ease-in',
        });

        grapesOverlayRef2.current?.animate(animateDownKeyframes, {
            duration: 1350,
            delay: 500,
            fill: 'forwards',
            easing: 'ease-in',
        });

        grapesOverlayRef3.current?.animate(animateDownKeyframes, {
            duration: 1500,
            delay: 250,
            fill: 'forwards',
            easing: 'ease-in',
        });

        await sleep(1600);
    };

    const startCitrusAnimation = async () => {
        const fadeInKeyframes = [
            {
                transform: 'translateY(0)',
                scale: 0,
                opacity: 0,
            },
            {
                transform: 'translateY(0)',
                scale: 1,
                opacity: 1,
            },
        ];

        citrusOverlayRef1.current?.animate(fadeInKeyframes, {
            duration: Math.random() * 500,
            fill: 'forwards',
        });

        citrusOverlayRef2.current?.animate(fadeInKeyframes, {
            duration: Math.random() * 500,
            fill: 'forwards',
        });

        citrusOverlayRef3.current?.animate(fadeInKeyframes, {
            duration: Math.random() * 500,
            fill: 'forwards',
        });

        await sleep(Config.PRESENT_EFFECT_OVERLAY_DURATIONS[PRESENT_TYPE.CITRUS]);

        const animateDownKeyframes = [
            {
                transform: 'translateY(0)',
            },
            {
                transform: 'translateY(20%)',
                opacity: 0,
            },
        ];

        citrusOverlayRef1.current?.animate(animateDownKeyframes, {
            duration: 1300,
            delay: 100,
            fill: 'forwards',
            easing: 'ease-in',
        });

        citrusOverlayRef2.current?.animate(animateDownKeyframes, {
            duration: 1050,
            delay: 500,
            fill: 'forwards',
            easing: 'ease-in',
        });

        citrusOverlayRef3.current?.animate(animateDownKeyframes, {
            duration: 1200,
            delay: 250,
            fill: 'forwards',
            easing: 'ease-in',
        });

        await sleep(1300);
    };

    const handlePresentEffect = useCallback(
        async (message: RTMessage<PresentEffect>) => {
            const presentEffect: PresentEffect = message.getPayloadObject();
            const isSender = presentEffect.sender_player_id === currentPlayer!.id;
            const isTarget = presentEffect.target_player_id === currentPlayer!.id;

            onPresentEffect.dispatch(presentEffect);

            setTimeout(() => {
                setPlayerPresentEffects((prevState) => {
                    prevState[presentEffect.target_player_id] ??= {
                        BANANA: false,
                        BERRIES: false,
                        CITRUS: false,
                        GRAPES: false,
                    };

                    prevState[presentEffect.target_player_id][presentEffect.present_type] = true;

                    return prevState;
                });

                setTimeout(() => {
                    setPlayerPresentEffects((prevState) => {
                        prevState[presentEffect.target_player_id][presentEffect.present_type] = false;
                        return prevState;
                    });
                }, Config.PRESENT_EFFECT_OVERLAY_DURATIONS[presentEffect.present_type] + 1000);
            }, 1000);

            if (isSender) {
                setBlurQuestion((prev) => prev + 1);
                setDisplayPresent(true);

                setLastPresent(presentEffect.present_type);
                await sleep(Config.PRESENT_DISPLAY_TIME);
                setDisplayPresent(false);

                setTimeout(() => setBlurQuestion((prev) => prev - 1), 750);
                startCurrentMovePercentInterval();
            }

            if (isTarget) {
                switch (presentEffect.present_type) {
                    case PRESENT_TYPE.BERRIES: {
                        AudioService.play(SoundKey.PRESENT_BERRIES_APPLY);
                        await move(15);
                        break;
                    }

                    case PRESENT_TYPE.BANANA: {
                        await sleep(1000);

                        AudioService.play(SoundKey.PRESENT_BANANA_APPLY);
                        setBanana(true);
                        setInputsDisabled(true);
                        await sleep(Config.PRESENT_EFFECT_OVERLAY_DURATIONS[PRESENT_TYPE.BANANA]);
                        setInputsDisabled(false);
                        setBanana(false);
                        break;
                    }

                    case PRESENT_TYPE.CITRUS: {
                        AudioService.play(SoundKey.PRESENT_CITRUS_APPLY);
                        setShowCitrusOverlay(true);
                        setInputsDisabled(true);

                        await startCitrusAnimation();

                        setInputsDisabled(false);
                        setShowCitrusOverlay(false);
                        break;
                    }

                    case PRESENT_TYPE.GRAPES: {
                        await sleep(1000);
                        AudioService.play(SoundKey.PRESENT_GRAPES_APPLY);
                        setShowGrapesOverlay(true);
                        setInputsDisabled(true);

                        await startGrapeAnimation();

                        setInputsDisabled(false);
                        setShowGrapesOverlay(false);
                        break;
                    }
                }
            }
        },
        [currentPlayer, playerPresentEffects, move, startCurrentMovePercentInterval],
    );

    useEffect(() => {
        GameLiftService.onMessage[OP_CODE.GAME_PRESENT_EFFECT].subscribe(handlePresentEffect);
        return () => GameLiftService.onMessage[OP_CODE.GAME_PRESENT_EFFECT].unsubscribe(handlePresentEffect);
    }, [handlePresentEffect]);

    const updateQuestions = (questions: Question[]) => {
        setQuestions(questions);

        if (!currentQuestion) {
            setCurrentQuestion(questions[0]);
            setNewQuestionAt(Date.now());
        }
    };

    useEffect(() => {
        BackendApiService.getExercises(getPositionInRace()).then(updateQuestions).catch(console.error);
    }, []);

    const renderCurrentQuestion = () => (
        <div className="absolute inset-0 z-10 size-full p-4">
            {currentQuestion ? (
                <div className="flex size-full items-center justify-center gap-4">
                    {banana ? (
                        <img
                            alt="banana"
                            className="absolute inset-0 z-50 h-full max-w-full object-contain drop-shadow-xl"
                            src="/images/character-power-ups/banana.webp"
                        />
                    ) : null}

                    {currentQuestion.text && currentQuestion.text.length ? (
                        <h1
                            className={cn(
                                'transition-all duration-700',
                                (blurQuestion > 0 || countDown) && 'blur-md duration-0 lg:blur-xl',
                                isMobile ? 'text-6xl' : 'md:text-3xl lg:text-5xl 2xl:text-7xl',
                            )}
                            data-testid="question-text"
                        >
                            {currentQuestion.text}
                        </h1>
                    ) : (
                        <div
                            className={cn(
                                'relative flex aspect-square h-full max-h-[90%] items-center justify-center overflow-hidden transition-all',
                                (blurQuestion > 0 || countDown) && 'blur-md duration-0 lg:blur-xl',
                            )}
                        >
                            <img src={currentQuestion.imageUrl} alt="" className="scale-[1.75]" />
                        </div>
                    )}

                    <div
                        className="flex items-center transition-colors"
                        data-testid="question-answer"
                        data-testvalue={currentQuestion.answer}
                        ref={currentQuestionRef}
                    >
                        {Config.ENVIRONMENT === 'Local' ? (<p className="text-xs italic">
                            &nbsp;
                            {currentQuestion.answer}
                        </p>) : null}

                        {currentQuestion.answer
                            .toString()
                            .split('')
                            .map((_, index) => (
                                <div
                                    key={index}
                                    className={cn('h-full outline-none', isMobile ? 'text-6xl' : 'md:text-3xl lg:text-5xl 2xl:text-7xl')}
                                    style={{
                                        width: '1ch',
                                    }}
                                >
                                    {givenAnswer[index] ?? <div className="opacity-0">0</div>}
                                </div>
                            ))}
                    </div>
                </div>
            ) : null}
        </div>
    );

    return (
        <>
            <div
                className={cn(
                    'pointer-events-auto fixed left-0 top-0 z-50 grid size-full place-items-center bg-black/50 opacity-100 backdrop-blur-sm transition-all',
                    countDown === 0 && 'pointer-events-none opacity-0',
                )}
                data-testid="countdown"
            >
                <h1 className="text-9xl font-bold text-white">{countDown !== 0 && countDown}</h1>
            </div>

            <div
                className={cn(
                    'pointer-events-none fixed inset-0 z-40 grid size-full place-items-center opacity-0 transition-all',
                    showGrapesOverlay && 'pointer-events-auto opacity-100',
                )}
                data-testid="grapes-overlay"
            >
                <div className="relative size-full">
                    <img
                        src={IMAGES.OVERLAY_GRAPES_3}
                        alt="grapes overlay"
                        className="absolute z-10 w-full object-contain p-8 drop-shadow-xl lg:left-[1%] lg:top-[10%] lg:w-2/5 lg:p-0"
                        ref={grapesOverlayRef1}
                    />
                    <img
                        src={IMAGES.OVERLAY_GRAPES_2}
                        alt="grapes overlay"
                        className="absolute top-1/4 z-20 object-contain drop-shadow-xl lg:right-1/3 lg:top-[10%] lg:w-1/3"
                        ref={grapesOverlayRef2}
                    />
                    <img
                        src={IMAGES.OVERLAY_GRAPES_1}
                        alt="grapes overlay"
                        className="absolute bottom-0 z-10 object-contain p-4 drop-shadow-xl lg:right-[5%] lg:top-[5%] lg:w-1/3 lg:p-0"
                        ref={grapesOverlayRef3}
                    />
                </div>
            </div>

            <div
                className={cn(
                    'pointer-events-none fixed inset-0 z-40 grid size-full place-items-center opacity-0 transition-all',
                    showCitrusOverlay && 'pointer-events-auto opacity-100',
                )}
                data-testid="citrus-overlay"
            >
                <div className="relative size-full">
                    <img
                        src={IMAGES.OVERLAY_CITRUS_2}
                        alt="grapes overlay"
                        className="absolute z-10 w-full object-contain p-8 drop-shadow-xl lg:left-[1%] lg:top-[10%] lg:w-2/5 lg:p-0"
                        ref={citrusOverlayRef1}
                    />
                    <img
                        src={IMAGES.OVERLAY_CITRUS_1}
                        alt="citrus overlay"
                        className="absolute top-1/4 z-20 object-contain drop-shadow-xl lg:right-1/3 lg:top-[10%] lg:w-1/3"
                        ref={citrusOverlayRef2}
                    />
                    <img
                        src={IMAGES.OVERLAY_CITRUS_3}
                        alt="citrus overlay"
                        className="absolute bottom-0 z-10 object-contain p-4 drop-shadow-xl lg:right-[5%] lg:top-[5%] lg:w-1/3 lg:p-0"
                        ref={citrusOverlayRef3}
                    />
                </div>
            </div>

            <div
                className={cn(
                    'bg-gradient-radial animate-fade-out pointer-events-none invisible fixed left-0 top-0 z-50 flex size-full items-center justify-center from-transparent to-black opacity-0 transition-all',
                    displayPresent && 'animate-fade-in pointer-events-auto select-none opacity-100',
                    lastPresent && 'visible',
                )}
                data-testid="present-display"
            >
                <div className="absolute aspect-square size-full scale-[2] opacity-50">
                    <CachedImage
                        src={IMAGES.PRESENT_SHINE}
                        className="absolute size-full animate-[spin_5s_linear_infinite] overflow-hidden rounded-full"
                        alt=""
                    />
                </div>

                {displayPresent && (
                    <CachedImage
                        src={presentImages[lastPresent!]}
                        className={cn('animate-scale-in drop-shadow-solidGray z-10 aspect-square w-1/2 min-w-32 max-w-64 delay-1000')}
                        alt={lastPresent!}
                    />
                )}
            </div>

            <div className="flex max-h-screen flex-col">
                <div className="h-[calc(100dvh-5rem)] overflow-hidden lg:h-[calc(100dvh-10rem)]" data-testid="main-game-container">
                    <TwoColumnGrid leftColumnSize="50%" rightColumnSize="50%">
                        <GameView
                            distanceIndicator={{
                                max: currentQuestion?.answerMoveDistance ?? 5,
                                current: indicatorHeight,
                                visible: !displayPresent,
                            }}
                            playerPresentEffects={playerPresentEffects}
                            onPresentEffect={onPresentEffect}
                            currentQuestionRef={currentQuestionRef}
                        />

                        <div className="relative flex h-full max-h-[50vh] items-center justify-center lg:max-h-full">
                            <LeaveButton onClick={leaveRoom} data-testid="leave-room-button" />

                            <div className="size-full">
                                <WoodenBackground />

                                <div className="flex size-full flex-col items-center justify-center" data-testid="game-content">
                                    {room && room.state === RoomState.GAME_OVER ? (
                                        <div className="flex w-full flex-col items-center justify-center">
                                            <h1 className="text-9xl font-bold text-white" data-testid="game-over">
                                                {currentPlayer && currentPlayer.position && currentPlayer.position >= 100 ? (
                                                    <div className="flex flex-col gap-4">
                                                        <h1 className="text-6xl drop-shadow-solidGray">🎉</h1>
                                                        <h2 className="text-4xl font-semibold drop-shadow-solidGray">Gewonnen!</h2>
                                                        <h3 className="text-2xl drop-shadow-sm">
                                                            <WinText page="game" />
                                                        </h3>

                                                        <div
                                                            className="mx-auto mt-4 h-8 w-96 rounded-lg bg-gray-800/90 shadow-solidGray"
                                                            data-testid="win-load-bar"
                                                        >
                                                            <LoadBar className="bg-yellow-400" duration={Config.ROOM_GAME_OVER_WAIT_TIME} />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="flex flex-col gap-4">
                                                        <h1 className="text-6xl drop-shadow-solidGray">👍</h1>
                                                        <h2 className="text-4xl font-semibold drop-shadow-solidGray">Goed geprobeerd!</h2>
                                                        <h3 className="text-2xl drop-shadow-sm">Volgende keer beter</h3>

                                                        <div
                                                            className="mx-auto mt-4 h-8 w-96 rounded-lg bg-gray-800/90 shadow-solidGray"
                                                            data-testid="lose-load-bar"
                                                        >
                                                            <LoadBar className="bg-yellow-400" duration={Config.ROOM_GAME_OVER_WAIT_TIME} />
                                                        </div>
                                                    </div>
                                                )}
                                            </h1>
                                        </div>
                                    ) : (
                                        <div className="font-zwijsen-frutiger-bold flex size-full max-h-[calc(100dvh-10rem)] flex-col items-center justify-center">
                                            {isMobile ? (
                                                <div className="relative aspect-[796/591] max-h-[90%] max-w-[90%]">
                                                    <CachedImage src={IMAGES.BACKGROUND_QUESTIONS} className="size-full" />
                                                    {renderCurrentQuestion()}
                                                </div>
                                            ) : (
                                                // Warning: Do not touch this as it's very sensitive and will break if you look at it the wrong way
                                                <div className="relative z-0 max-h-[95%] w-full max-w-[90%]">
                                                    <div className="relative -z-0 mx-auto aspect-[563/543] max-h-[60%] w-full max-w-[80%]">
                                                        <CachedImage src={IMAGES.BACKGROUND_ANSWERS} className="size-full" />
                                                    </div>

                                                    <div className="aspect-[796/591] max-h-[50%]"></div>

                                                    <div className="absolute bottom-[8%] left-1/2 z-10 aspect-[796/591] h-auto max-h-[60%] w-full max-w-full -translate-x-1/2">
                                                        <CachedImage src={IMAGES.BACKGROUND_QUESTIONS} className="size-full" />
                                                        {renderCurrentQuestion()}

                                                        <div className="absolute -top-full left-0 size-full overflow-hidden p-[2%]">
                                                            <div className="flex size-full flex-col items-center justify-end">
                                                                {previousAnswers.length ? (
                                                                    <>
                                                                        {previousAnswers.map((answer, idx) => (
                                                                            <div
                                                                                key={answer.id}
                                                                                className={cn(
                                                                                    'z-10 flex items-center whitespace-nowrap opacity-0 transition-all',
                                                                                    idx === previousAnswers.length - 1 &&
                                                                                        'opacity-100 lg:text-3xl 2xl:text-5xl',
                                                                                    idx === previousAnswers.length - 2 &&
                                                                                        'opacity-80 lg:text-2xl 2xl:text-4xl',
                                                                                    idx === previousAnswers.length - 3 &&
                                                                                        'opacity-60 lg:text-xl 2xl:text-3xl',
                                                                                    idx === previousAnswers.length - 4 &&
                                                                                        'opacity-0 lg:text-lg 2xl:text-2xl 2xl:opacity-40',
                                                                                )}
                                                                                data-testid={`previous-answer-${idx}`}
                                                                            >
                                                                                <span
                                                                                    dangerouslySetInnerHTML={{ __html: answer.text }}
                                                                                ></span>
                                                                                &nbsp;
                                                                                <span
                                                                                    className={cn(
                                                                                        'font-black',
                                                                                        answer.isCorrect
                                                                                            ? 'text-[#92BA3D]'
                                                                                            : 'text-[#E14C56]',
                                                                                    )}
                                                                                >
                                                                                    {answer.givenAnswer}
                                                                                </span>
                                                                            </div>
                                                                        ))}
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </TwoColumnGrid>
                </div>

                <div className="relative w-full">
                    {banana ? (
                        <div className="absolute bottom-0 grid size-full place-items-center p-4">
                            <img alt="" className="h-full max-w-full drop-shadow-xl" src="/images/character-power-ups/banana.webp" />
                        </div>
                    ) : (
                        <></>
                    )}

                    <Keyboard
                        inputClicked={handleInput}
                        disabled={inputsDisabled || room?.state !== RoomState.GAME || displayPresent || blurQuestion > 0}
                        data-testid="keyboard"
                    />
                </div>
            </div>
        </>
    );
};
